import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useLangNavigate as useNavigate } from '../modules/i18n';
import { useParams } from 'react-router-dom';

import User from '../models/User';
import { dateFormats, useSettings } from '../models/Settings';
import { Colors, contactUrl } from '../Resources';

import SEO from '../modules/SEO';
import NavBar from './NavBar';
import Footer from './Footer';
import { Workspace } from './Workspace';
import { WorkspaceSection } from './WorkspaceSection';
import { Typography, Divider, Grid, Input, Button, Link, Box } from '@mui/joy';
import { List, ListItemDecorator, ListItemButton } from '@mui/joy';
import { Select, Option } from '@mui/joy';
import { Modal, ModalDialog, DialogTitle, ModalClose } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import { faCircleUser, faScrewdriverWrench, faRightFromBracket } from '@fortawesome/free-solid-svg-icons'


const Settings: React.FC = () => {
	const { t } = useTranslation(['dashboard', 'account']);
	const { page } = useParams();
	const [openChangePassword, setOpenChangePassword] = React.useState<boolean>(false);
	const settings = useSettings();
	const nav = useNavigate();
	const user = User.me();

	return <>
		<SEO
			title='Mogua | Settings'
			description="Web to app parameter passing solution. Track Android and iOS apps' installation from web referrals with our attribution SDK."
			url='https://www.mogua.io/settings' />
		<NavBar wide />
		<Divider />
		<Workspace sidebar={
			<List size='sm'>
				<ListItemButton selected={page === 'general'} onClick={()=>nav('/settings/general')}>
					<ListItemDecorator><FA icon={faScrewdriverWrench} /></ListItemDecorator>{t('sidebar.general')}
				</ListItemButton>
				<ListItemButton selected={page === 'account'} onClick={()=>nav('/settings/account')}>
					<ListItemDecorator><FA icon={faCircleUser} /></ListItemDecorator>{t('sidebar.account')}
				</ListItemButton>
				<Divider sx={{ my: 2 }} />
				<ListItemButton onClick={()=>user?.signOut({
					onSuccess: () => nav('/sign-in'),
					onError: (error) => alert(error),
				})}>
					<ListItemDecorator><FA icon={faRightFromBracket} /></ListItemDecorator>{t('sidebar.signOut')}
				</ListItemButton>
				<ListItemButton sx={{ fontWeight: 'bold', visibility: 'collapse', lineHeight: 0 }}><ListItemDecorator />ABCDEFGHIJKLMNOPQ</ListItemButton>
			</List>
		}>
			{page === 'account' ?
			<WorkspaceSection title={t('sidebar.account')}>
				<Grid container alignItems='center' spacing={2}>
					<Grid xs={1}><Typography fontSize='sm'>Email</Typography></Grid>
					<Grid xs={3}><Input value={user?.email} disabled /></Grid>
					<Grid xs={8} />
					<Grid xs={1}><Typography fontSize='sm'>{t('password', { ns: 'account' })}</Typography></Grid>
					<Grid xs={3}><Input type='password' value='12345678' disabled /></Grid>
					<Grid xs={8}><Button variant='outlined' color='neutral' onClick={()=>setOpenChangePassword(true)}>{t('changePassword')}</Button></Grid>
				</Grid>
				<ChangePasswordDialog closeState={!openChangePassword} closeHandler={()=>setOpenChangePassword(false)} />
			</WorkspaceSection> :
			page === 'general' ?
			<WorkspaceSection title={t('sidebar.general')}>
				<Grid container alignItems='center' spacing={2}>
					<Grid xs={1}><Typography fontSize='sm'>Date Format</Typography></Grid>
					<Grid xs={3}>
						<Select size='sm' defaultValue={settings.dateFormat} onChange={(event, value: string | null)=>{
							if (!value) return;
							settings.dateFormat = value;
						}}>
							{dateFormats.map(e=><Option key={e.format} value={e.format} label={e.format}>{e.format}<Box component='span' flex={1} />{e.sample}</Option>)}
						</Select>
					</Grid>
					<Grid xs={8} />
				</Grid>
				<ChangePasswordDialog closeState={!openChangePassword} closeHandler={()=>setOpenChangePassword(false)} />
			</WorkspaceSection> :
			<></>}
		</Workspace>
		<Footer />
	</>;
}

export default Settings;


const ChangePasswordDialog: React.FC<{ closeState: boolean, closeHandler: ()=>void }> = ({ closeState, closeHandler }) => {
	const { t } = useTranslation('dashboard');
	const [sentCount, setSentCount] = React.useState<number>(0);
	const [loading, setLoading] = React.useState<boolean>(false);
	const user = User.me();

	return <Modal open={!closeState} onClose={()=>closeHandler()}>
		<ModalDialog variant='soft'>
			<DialogTitle>{t('changePassword')}</DialogTitle>
			<ModalClose variant="plain" sx={{ m: 1 }} />
			{sentCount === 0 ?
			<>
				<Typography sx={{ color: Colors.gray2 }}>
					<Trans t={t} i18nKey='changePasswordP1' />
				</Typography>
				<Button loading={loading} sx={{ mt: 2, width: '100%' }} onClick={()=>{
					setLoading(true);
					User.checkEmail(user!.email, {
						onSuccess: () => {
							setLoading(false);
							setSentCount(1);
						},
						onError: (error) => {
							setLoading(false);
							alert(error);
						},
					});
				}}>{t('changePasswordA1')}</Button>
			</> :
			sentCount === 1 ?
			<Typography>
				<Trans t={t} i18nKey='changePasswordP2' components={[<Link component='button' onClick={()=>{
					User.resendEmail(user!.email, {
						onSuccess: () => setSentCount(2),
						onError: (error) => alert(error),
					});
				}} />]} />
			</Typography> :
			<Typography>
				<Trans t={t} i18nKey='changePasswordP3' components={[<Link href={contactUrl} />]} />
			</Typography>}
		</ModalDialog>
	</Modal>;
}


