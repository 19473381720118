import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export interface SEOParams {
  title: string;
  description: string;
  url: string;
  children?: React.ReactNode;
}
const SEO : React.FC<SEOParams> = (params:SEOParams) => {
    return <HelmetProvider>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{params.title}</title>
            <meta name="description" content={params.description} />
            {params.children}
            {params.url && <link rel="canonical" href={params.url} />}
        </Helmet>
    </HelmetProvider>;
}

export default SEO;


