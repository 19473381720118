import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLangNavigate as useNavigate } from '../modules/i18n';

import { Colors, Dimens } from '../Resources';

import SEO from '../modules/SEO';
import NavBar from './NavBar';
import Footer from './Footer';
import { Box, Typography, Link, Divider } from '@mui/joy';


const NotFound: React.FC = () => {
	const { t } = useTranslation('dashboard');
	const nav = useNavigate();
	return (<>
		<SEO
            title="Mogua: Web to app attribution solution"
            description="Web to app parameter passing solution. Track Android and iOS apps' installation from web referrals with our attribution SDK."
            url='https://www.mogua.io' />
        <NavBar />
        <Divider />
        <Box m={12} mx='auto' sx={{ flex: 1, ...Dimens.autoContentWidth }}>
			<Typography sx={{ color: Colors.black, fontSize: '32px', fontWeight: 'bold', letterSpacing: '-0.05rem' }}>{t('notFoundTitle')}</Typography>
			<Typography sx={{ color: Colors.gray2, fontSize: '16px' }}>{t('notFoundSubtitle')}</Typography>
			<br />
			<Link onClick={()=>nav('/')}>{t('notFoundAction')}</Link>
		</Box>
		<Footer />
	</>);
}

export default NotFound;
