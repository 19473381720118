import Cookie from 'js-cookie';
import { useState, useEffect } from 'react';
import { serviceConfig } from '../GlobalConfig';
import dayjs from 'dayjs';

export const dateFormats = [
	{ format: 'MM/DD/YYYY', sample: '12/31/2024' },
	{ format: 'DD/MM/YYYY', sample: '31/12/2024' },
	{ format: 'YYYY/MM/DD', sample: '2024/12/31' },
	{ format: 'MM-DD-YYYY', sample: '12-31-2024' },
	{ format: 'DD-MM-YYYY', sample: '31-12-2024' },
	{ format: 'YYYY-MM-DD', sample: '2024-12-31' },
	{ format: 'MMM DD, YYYY', sample: 'Dec 31, 2024' },
];

class Settings {

	private _dateFormat: string;
	private _version: string;
	private _versionKnown: string;
	private _versionSyncDate: string;
	private _updateListeners: Array<()=>void>;

	get dateFormat() {
		return this._dateFormat;
	}

	set dateFormat(value: string) {
		this._dateFormat = value;
		this.save();
	}

	get version() {
		return this._version;
	}

	get versionSyncDate() {
		return dayjs(this._versionSyncDate);
	}

	addUpdateListener(listener: ()=>void) {
		this._updateListeners.push(listener);
	}

	removeUpdateListener(listener: ()=>void) {
		const index = this._updateListeners.indexOf(listener);
		if (index === -1) return;
		this._updateListeners.splice(index, 1);
	}

	constructor(obj: any) {
		this._dateFormat = obj._dateFormat ?? dateFormats[0].format;
		this._version = obj._version ?? 'unknown';
		this._versionKnown = obj._versionKnown ?? 'unknown';
		this._versionSyncDate = obj._versionSyncDate ?? '1970-1-1';
		this._updateListeners = [];
	}

	static load = () => {
		let data = Cookie.get('settings');
		let obj = data ? JSON.parse(data) : new Object();
		let settings = new Settings(obj);
		settings.syncVersion();	
		return settings;
	}

	private isDiffTo(s: Settings) {
		return this._dateFormat !== s._dateFormat ||
		this._version !== s._version ||
		this._versionKnown !== s._versionKnown ||
		this._versionSyncDate !== s._versionSyncDate;
	}

	private save() {
		const data = Cookie.get('settings');
		Cookie.set('settings', JSON.stringify(this));
		if (!data || this.isDiffTo(new Settings(JSON.parse(data)))) {
			this._updateListeners.forEach(e => e());
		}
	}

	private syncVersion() {
		if (this._version === serviceConfig.version) return;
		this._version = serviceConfig.version ?? this._version;
		this._versionSyncDate = dayjs().format();
		this.save();
	}

	knowVersion() {
		this._versionKnown = this._version;
		this.save();
	}

	isVersionKnown() {
		return this._versionKnown === this._version || dayjs().diff(this.versionSyncDate, 'h') > 48;
	}

}

const instance = Settings.load();

export function useSettings() {
	const [update, setUpdate] = useState(0);
	useEffect(()=>{
		const listener = ()=>setUpdate(dayjs().get('ms'));
		instance.addUpdateListener(listener);
		return ()=>{
			instance.removeUpdateListener(listener);
		};
	}, []);
	return instance;
}

