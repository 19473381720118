import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import dayjs, { Dayjs } from 'dayjs';
import { http, ContentType } from '../Utils';
import { Colors, MoguaSVG } from '../Resources';
import { serviceConfig } from '../GlobalConfig';

import Footer from './Footer';
import { DateMenu } from './FilterToolbar';
import { Workspace } from './Workspace';
import { dataGridSx } from './WorkspaceData';
import { WorkspaceSection } from './WorkspaceSection';
import { Link, Card, Input, Divider, Button, Box, Typography, TypographyProps, Grid, CircularProgress } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faDoorOpen, faArrowRight, faMagnifyingGlass, faCircleCheck, faArrowsRotate, faBan, faClock } from '@fortawesome/free-solid-svg-icons';

import { DataGrid, GridColDef, GridRenderEditCellParams } from '@mui/x-data-grid';
import {
  	Experimental_CssVarsProvider as CssVarsProvider,
  	experimental_extendTheme as extendTheme,
} from '@mui/material/styles';
const theme = extendTheme();


const config = Array.from<GridColDef>([
	{ field: 'id', headerName: 'No.' },
	{ field: 'email', headerName: 'Email', renderCell: (params: GridRenderEditCellParams) => <Typography
		endDecorator={<FA icon={faCircleCheck} style={{ color: params.row.status === 1 ? '#00BF40' : '#ddd' }} />}
		sx={{ width: 'max-content' }}>{params.value}</Typography>},
	{ field: 'apps', headerName: 'Apps', renderCell: (params: GridRenderEditCellParams) => params.value.map((e: any) => <AppField display='inline-flex' app={e} sx={{ ml: 3 }} />) },
	{ field: 'created', headerName: 'User Created', valueGetter: (value) => dayjs(value * 1000).format('YYYY-MM-DD HH:mm:ss') },
]).map((e) => {
	e.disableColumnMenu = true;
	e.resizable = false;
	e.sortable = false;
	e.flex = e.field === 'id' ? 0.25 : 1;
	e.align = 'center';
	e.headerAlign = e.field === 'email' ? 'left' : 'center';
	return e;
});


interface AppFieldProps extends TypographyProps {
	app: any;
}

const AppField: React.FC<AppFieldProps> = ({ app, ...props }) => {
	const [integrated, setIntegrated] = React.useState<Array<boolean> | undefined>(undefined);
	
	React.useEffect(() => {
		fetch(`https://www.mogua.io/backdoor.json?action=appdetails&id=${app.appId}`, { method: 'GET' })
		.then(res => res.json())
		.then(data => {
			if (!data.success) return;
			let states: Array<boolean> = [];
			states[0] = data.webCount > 0;
			states[1] = data.iosCount > 0;
			states[2] = data.androidCount > 0;
			setIntegrated(states);
		}).catch(e => setIntegrated(undefined));
	}, [app.appId]);

	return <Typography {...props} endDecorator={<Box display='flex' gap={0.5}>
		{integrated === undefined ?
		<CircularProgress sx={{ '--CircularProgress-size': '16px', '--CircularProgress-thickness': '4px' }} /> : 
		<>
			<Typography fontSize={12} fontWeight='bold' textColor={integrated[0] ? '#00BF40' : '#ddd'}>Web</Typography>
			<Typography fontSize={12} fontWeight='bold' textColor={integrated[1] ? '#00BF40' : '#ddd'}>iOS</Typography>
			<Typography fontSize={12} fontWeight='bold' textColor={integrated[2] ? '#00BF40' : '#ddd'}>And</Typography>
		</>}
		{app.subscription.autoRenew ?
		<Typography fontSize={12} fontWeight='bold' textColor='#00BF40'>Sub</Typography> :
		(dayjs(app.subscription.expire * 1000) < dayjs()) ?
		<Typography fontSize={12} fontWeight='bold' textColor='#D91616'>Exp</Typography> :
		<Typography fontSize={12} fontWeight='bold' textColor='#ddd'>Try</Typography>}
	</Box>}>{app.name}</Typography>;
}





const Backdoor: React.FC = () => {
	const [loading, setLoading] = React.useState<boolean>(false);
	const [users, setUsers] = React.useState<Array<any>>();
	const [apps, setApps] = React.useState<Array<any>>();

	return (<>
		<NavBar />
		<Divider />
		<Workspace>
			<FilterToolbar loading={loading}  onSubmit={(filters)=>{
				setUsers(undefined);
				setApps(undefined);
				setLoading(true);
				fetch(`https://www.mogua.io/backdoor.json?action=recent&start=${filters.dateStart.unix()}&end=${filters.dateEnd.unix()}`, { method: 'GET' })
				.then(res => res.json())
				.then(data => {
					setLoading(false);

					const appsData = data.newApps.map((e: any) => Object.defineProperties(e, { integrated: { value: false, writable: true } }));
					setApps(appsData);
					
					const usersData = data.newUsers.map((e: any, i: number, a: Array<any>) => Object.defineProperties(e, {
						id: { value: i + 1 },
						apps: { value: appsData.filter((ei: any) => ei.ownerId === e.userId) ?? [] },
					}));
					setUsers(usersData);
				}).catch(e => setLoading(false));
			}} />
			<WorkspaceSection title='Overview'>
				{users && apps ?
				<Grid container spacing={2} pl={4}>
					<Grid xs={3}><SummarySegment label='User Sign-up' value={users!.length} /></Grid>
					<Grid xs={3}><SummarySegment label='User Verified' value={users!.filter(e => e.status === 1).length} /></Grid>
					<Grid xs={3}><SummarySegment label='User Created App' value={users!.filter(e => e.apps.length > 0).length} /></Grid>
					<Grid xs={3}><SummarySegment label='User Subscribed' value={users!.filter(e => e.apps.filter((ei: any) => ei.subscription.autoRenew).length > 0).length} /></Grid>
					<Grid xs={3}><SummarySegment label='App Created' value={apps!.length} /></Grid>
					<Grid xs={3}><SummarySegment label='App Integrated' value={0} /></Grid>
				</Grid> :
				loading ? 
				<CircularProgress sx={{ my: 8.5, alignSelf: 'center' }} /> :
				<></>}
			</WorkspaceSection>
			<Card sx={{ p: 0, flex: 1, alignItems: 'center', justifyContent: 'center' }}>
				{users && users.length === 0 ?
				<Box>
					<Typography sx={{ color: Colors.black, fontSize: 32, fontWeight: 'bold', letterSpacing: '-0.05rem' }}>No data to display</Typography>
					<Typography sx={{ color: Colors.gray2, fontSize: 16 }}>Please check the filters and try again.</Typography>
				</Box> : 
				users ?
				<CssVarsProvider theme={theme}>
					<DataGrid autoHeight rows={users} columns={config} sx={dataGridSx} />
				</CssVarsProvider> :
				loading ? 
				<CircularProgress sx={{ my: 8.5, alignSelf: 'center' }} /> :
				<Typography>Click the <strong>🔍 Search</strong> to get started.</Typography>}
			</Card>
		</Workspace>
		<Footer />
	</>);
}

export default Backdoor;


const NavBar: React.FC = () => {
	return (<Box component='nav' display='flex' alignItems='center' justifyContent='center' sx={{ backgroundColor: 'white' }}>
		<Box flex={1} mx={2} my={1} display='flex' flexWrap='wrap' gap={4}>
			<Link underline='none' href='/'><MoguaSVG /></Link>
			<Typography
				fontSize={24} fontWeight='bold' sx={{ my: 'auto', color: '#333' }}
				startDecorator={<FA icon={faDoorOpen} />}>BACKDOOR</Typography>
		</Box>
	</Box>);
}


const SummarySegment: React.FC<{ label: string, value: number }> = ({ label, value }) => {
	return <>
		<Typography sx={{ color: Colors.gray3, fontSize: 16, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{label}</Typography>
		<Typography sx={{ color: Colors.black, fontSize: 32, fontWeight: 'bold', letterSpacing: '-0.05rem' }}>{value}</Typography>
	</>;
}






type DateFilters = {
	dateStart: Dayjs,
	dateEnd: Dayjs,
}

const dateFiltersToSearchParams = (filters: DateFilters) => {
	const sp: URLSearchParams = new URLSearchParams();
	sp.set('start', filters.dateStart.format('YYYY-MM-DD'));
	sp.set('end', filters.dateEnd.format('YYYY-MM-DD'));
	return sp;
}

const searchParamsToDateFilters = (sp: URLSearchParams) => {
	let d1 = dayjs(sp.get('start'));
	d1 = d1.isValid() ? d1 : dayjs();
	let d2 = dayjs(sp.get('end'));
	d2 = d2.isValid() ? d2 : dayjs();
	return {
		dateStart: dayjs.min(d1, d2)?.startOf('d'),
		dateEnd: dayjs.max(d1, d2)?.endOf('d'),
	} as DateFilters;
}

interface FilterToolbarProps {
	loading?: boolean;
	onSubmit?: (obj: any) => void;
}

const dateInputSx = {
	min: '1970-01-01',
	max: '2042-12-31',
	width: 150,
}

const FilterToolbar: React.FC<FilterToolbarProps> = ({ loading = false, onSubmit }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const filters = searchParamsToDateFilters(searchParams);
	const [start, setStart] = React.useState<Dayjs>(filters.dateStart);
	const [end, setEnd] = React.useState<Dayjs>(filters.dateEnd);

	React.useEffect(() => {
		onSubmit && onSubmit(filters);
	}, []);

	return (<>
		<Card component='form' orientation='horizontal' sx={{
			alignItems: 'center',
			flexWrap: 'wrap',
			'& .fa-arrow-right': { color: Colors.gray2 },
		}} onSubmit={(event) => {
			event.preventDefault();
			const data = new FormData(event.currentTarget);
			const filters = searchParamsToDateFilters(data as URLSearchParams);
			const sp = dateFiltersToSearchParams(filters);

			setStart(filters.dateStart);
			setEnd(filters.dateEnd);
			setSearchParams(sp);

			onSubmit && onSubmit(filters);
		}}>
			<Input size='sm' disabled={loading} type='date' key={'s' + start?.format()} defaultValue={start?.format('YYYY-MM-DD')} name='start' sx={dateInputSx} />
			<FA icon={faArrowRight} />
			<Input size='sm' disabled={loading} type='date' key={'e' + end?.format()} defaultValue={end?.format('YYYY-MM-DD')} name='end' sx={dateInputSx} />
			<DateMenu loading={loading} onSelected={(start, end) => {
				setStart(start);
				setEnd(end);
			}} />
			<Button size='sm' loading={loading} type='submit' startDecorator={<FA icon={faMagnifyingGlass} />}>Search</Button>
		</Card>
	</>);
}


