import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useSettings } from '../models/Settings';

import { Dimens } from '../Resources';

import SEO from '../modules/SEO';
import NavBar from './NavBar';
import Footer from './Footer';
import Markdown from './MoguaMarkdown';
import { Workspace } from './Workspace';
import { WorkspaceSection } from './WorkspaceSection';
import { Divider } from '@mui/joy';
import { List, ListItem, ListItemDecorator } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import { faClipboardList } from '@fortawesome/free-solid-svg-icons'


const Changelog: React.FC = () => {
	const settings = useSettings();
	const { t } = useTranslation('docs');
	const docs = t('changelog', { returnObjects: true, defaultValue: [] }) as Array<any>;
	const doc = docs[0];
	
	React.useEffect(()=>settings.knowVersion(), [settings]);
	
	return <>
		<SEO
			title='Mogua | Changelog'
			description='Web to app parameter passing solution. Track Android and iOS apps’ installation from web referrals with our attribution SDK.'
			url='https://www.mogua.io/changelog' />
		<NavBar wide />
		<Divider />
		<Workspace sidebar={
			<List size='sm'>
				<ListItem>
					<ListItemDecorator><FA icon={faClipboardList} /></ListItemDecorator>{doc.title}
				</ListItem>
			</List>
		}>
			<WorkspaceSection>
				<Markdown src={doc.src} sx={{ maxWidth: Dimens.maxFocusWidth }} />
			</WorkspaceSection>
		</Workspace>
		<Footer />
	</>;
}

export default Changelog;

