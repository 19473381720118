import { http } from '../Utils';
import { serviceConfig } from '../GlobalConfig';
import { Dayjs } from 'dayjs';

export enum Platform {
	all = 'all',
	android = 'android',
	ios = 'ios',
}

export enum EventType {
	all = 'all',
	appDownload = 'download',
	appInstalled = 'app_init',
	appWakeup = 'app_wakeup',
}

export type DataFilter = {
	appId: string,
	paramKey: string,
	paramValue: string,
	platform: Platform,
	eventType: EventType,
	dateStart: Dayjs,
	dateEnd: Dayjs,
}

interface Callback {
	onSuccess: (value?: any) => void;
	onError: (value: any) => void;
}

export default class Data {

	filters: DataFilter;

	constructor(filters: DataFilter) {
		this.filters = filters;
	}
	
	getOverview = (callback?: Callback) => {
		let host = serviceConfig.backendhost + '/s_api/statisticsv2';
		host += '?id=' + this.filters.appId;
		host += '&start=' + encodeURIComponent(this.filters.dateStart.format());
		host += '&end=' + encodeURIComponent(this.filters.dateEnd.format());
		host += '&device=' + encodeURIComponent(this.filters.platform);
		host += '&eventName=' + encodeURIComponent(this.filters.eventType);
		host += '&dedupe=true';
		let others: { key: string, value: string }[] = [];
		others.push({ key: this.filters.paramKey, value: this.filters.paramValue });
		host += '&filter=' + encodeURIComponent(JSON.stringify(others));
		http(host, {
			body: {},
			method: 'GET'
		}).then((res) => {
			if (res.success) {
				callback?.onSuccess(res.statistics);
			} else {
				callback?.onError(res.msg);
			}
		}).catch((e) => callback?.onError(e));
	}

	getActivities = (callback?: Callback) => {
		let host = serviceConfig.backendhost + '/s_api/batchtimeline';
		host += '?id=' + this.filters.appId;
		host += '&start=' + encodeURIComponent(this.filters.dateStart.format());
		host += '&end=' + encodeURIComponent(this.filters.dateEnd.format());
		host += '&device=' + encodeURIComponent(this.filters.platform);
		host += '&eventName=' + encodeURIComponent(this.filters.eventType);
		host += '&dedupe=true';
		let others: { key: string, value: string }[] = [];
		others.push({ key: this.filters.paramKey, value: this.filters.paramValue });
		host += '&filter=' + encodeURIComponent(JSON.stringify(others));
		http(host, {
			body: [
				{ 'task': 'simple', 'eventName': 'download', 'interval': 1, 'outputType': 'countUid' },
				{ 'task': 'simple', 'eventName': 'app_init', 'interval': 1, 'outputType': 'count' },
				{ 'task': 'simple', 'eventName': 'app_wakeup', 'interval': 1, 'outputType': 'count' },
				{ 'task': 'simple', 'eventName': 'app_init|app_launch', 'interval': 1, 'outputType': 'countUid' },
			    { 'task': 'retention', 'days': 1, 'interval': 1 },
			],
			method: 'POST'
		}).then((res) => {
			if (res.success) {
				const data = new Array<any>();
				const g = res.graph;
				for (let i = 0; i < g[0].length; i++) {
					data.push({
						id: i,
						date: g[0][i].time,
						download: g[0][i].value,
						install: g[1][i].value,
						open: g[2][i].value,
						active: g[3][i].value,
						d1r: g[4][i].value,
					});
				}
				callback?.onSuccess(data);
			} else {
				callback?.onError(res.msg);
			}
		}).catch((e) => callback?.onError(e));
	}

	getParameters = (callback?: Callback) => {
		let host = serviceConfig.backendhost + '/s_api/parameters';
		host += '?id=' + this.filters.appId;
		host += '&start=' + encodeURIComponent(this.filters.dateStart.format());
		host += '&end=' + encodeURIComponent(this.filters.dateEnd.format());
		host += '&device=' + encodeURIComponent(this.filters.platform);
		host += '&eventName=' + encodeURIComponent(this.filters.eventType);
		let others: { key: string, value: string }[] = [];
		others.push({ key: this.filters.paramKey, value: this.filters.paramValue });
		host += '&filter=' + encodeURIComponent(JSON.stringify(others));
		http(host, {
			body: {},
			method: 'GET'
		}).then((res) => {
			if (res.success) {
				for(let i = 0; i < res.data.length; i++) {
					res.data[i].id = i;
				}
				callback?.onSuccess(res.data);
			} else {
				callback?.onError(res.msg);
			}
		}).catch((e) => callback?.onError(e));
	}

}


