import * as React from 'react';

import { Colors } from '../Resources';

import { Typography, Tooltip } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import { faCircleInfo  } from '@fortawesome/free-solid-svg-icons'


type DataCardProps = {
	number: number | string;
	title: string;
	subtitle?: string;
	unit?: string;
	tips?: string;
}

const DataCard: React.FC<DataCardProps> = ({ number, title, subtitle, unit, tips }) => {
	const titleSx = { color: Colors.black, fontSize: 16, fontWeight: 'bold', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' };
	const subtitleSx = { color: Colors.gray2, fontSize: 14, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' };
	const numberSx = { color: Colors.black, fontSize: 32, fontWeight: 'bold', letterSpacing: '-0.05rem' };
	const unitSx = { color: Colors.black, fontSize: 16 };
	return <>
		<Typography
			endDecorator={tips && <Tooltip arrow variant='outlined' title={tips} placement='top' sx={{ maxWidth: 320 }}><FA icon={faCircleInfo} style={{ color: '#ccc' }} /></Tooltip>}
			sx={titleSx}>{title}</Typography>
		{subtitle && <Typography sx={subtitleSx}>{subtitle}</Typography>}
		<Typography sx={numberSx}>{number.toLocaleString()} {unit && <Typography sx={unitSx}>{unit}</Typography>}</Typography>
	</>
}

export default DataCard;


