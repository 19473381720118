import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLangNavigate as useNavigate } from '../modules/i18n';

import User from '../models/User';

import { Colors } from '../Resources';
import { Utils } from '../GlobalConfig';

import SEO from '../modules/SEO';
import { SignMid } from './SignMid';
import { HeroCard } from './HeroCard';
import { EmailInput, PasswordInput } from './MoguaInput'
import { Box, Link, Typography, Checkbox, Button, Chip } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleRight } from '@fortawesome/free-solid-svg-icons'


const SignIn: React.FC = () => {
	const { t } = useTranslation('account');
	const [emailValid, setEmailValid] = React.useState<string | null>(null);
	const [emailError, setEmailError] = React.useState<string | null>(null);
	const [remember, setRemember] = React.useState<boolean>(true);
	const [loading, setLoading] = React.useState<boolean>(false);

	const [searchParams, setSearchParams] = useSearchParams();
	const callback = searchParams.get('callback');
	const nav = useNavigate();

	const isMobile = Utils.isMobile();
	const size = isMobile ? 'lg' : 'md';

	if (emailValid == null) {
		const chip = <Chip
			component='span' color='primary'
			onClick={()=>nav('/sign-up' + (callback ? `?callback=${callback}` : ''))}
			endDecorator={<FontAwesomeIcon icon={faCircleRight} />}>{t('signInToSignUp2')}</Chip>;
		const suffix = <Typography endDecorator={chip} sx={{ color: Colors.gray3, fontSize: 14 }}>{t('signInToSignUp1')}</Typography>;
		return (<>
			<SEO
				title="Mogua Sign in | Sign in to your account"
				description="Sign in to your Mogua account. Track your app's installation."
				url="https://www.mogua.io/sign-in" />
			<HeroCard title={t('signInTitle')} suffix={suffix}>
				<form onSubmit={(event) => {
					event.preventDefault();
					const data = new FormData(event.currentTarget);
				
					const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
					const email = data.get('email') as string;
					if (emailReg.test(email) === false) {
						setEmailError(t('emailInvalid'));
						return;
					}

					const password = data.get('pwd') as string;

					setLoading(true);
					User.signIn(email, password, remember, {
						onSuccess: () => nav(callback ?? '/dashboard'),
						onError: (error) => {
							setLoading(false);
							if (error === 'needVerifyEmail') {
								setEmailValid(email);
							} else {
								alert(error);
							}
						},
					});
			    }}>
					<EmailInput required size={size} name='email' disabled={loading} errMsg={emailError} onChange={()=>setEmailError(null)} />
					<PasswordInput required size={size} name='pwd' sx={{ mt: 2 }} disabled={loading} />
					<Box display='flex' alignItems='center' justifyContent='space-between' mt={3}>
						<Checkbox size='sm' label={t('signInStay1w')} defaultChecked onChange={()=>setRemember(!remember)} disabled={loading} />
						<Link sx={{ fontSize: 14 }} onClick={()=>nav('/reset')}>{t('forgotPassword')}</Link>
					</Box>
					<Button size={size} type='submit' loading={loading} sx={{ width: '100%', mt: 3 }}>{t('signInButton')}</Button>
				</form>
			</HeroCard>
		</>);
	} else {
		return <SignMid email={emailValid} />;
	}
}

export default SignIn;


