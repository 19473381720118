import * as React from 'react';
import { useLangNavigate as useNavigate } from '../modules/i18n';

import { Colors, MoguaSVG } from '../Resources';

import { Box, Link, Card, Typography } from '@mui/joy';


export const HeroCard: React.FC<{ title: string, suffix?: React.ReactNode, children: React.ReactNode }> = ({ title, suffix, children }) => {
	const nav = useNavigate();
	return (<>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ height: "100%" }}>
			<Box display="flex" alignItems="center" justifyContent="center" sx={{ width: "100%" }}>
				<Box sx={{ maxWidth: "480px", flex: 1, mx: 2, my: 3 }}>
					<Link ml={1} mb={1.5} onClick={()=>nav('/')}><MoguaSVG /></Link>		
					<Card size="lg">
						<Typography sx={{ color: Colors.black, fontSize: 20, fontWeight: 'bold' }}>{title}</Typography>
						{children}
					</Card>
				</Box>
			</Box>
			{suffix}
			<Typography mt={4} sx={{ color: Colors.gray3, fontSize: 12 }}>© 2024 Omnimind</Typography>
    	</Box>
    </>);
}