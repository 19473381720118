export const serviceConfig = {
    backendhost: process.env.REACT_APP_BACKEND_HOST,
    host: process.env.REACT_APP_API_BASE_URL,
    entrypoint: process.env.REACT_APP_ENTRYPOINT,
    version: process.env.REACT_APP_VERSION,
    lng: "en-US",
}

export const Utils = {
    isMobile: () => {
        return navigator.userAgent.toLowerCase().indexOf("mobile") > -1;
    },
    isIOS: () => {
        const a = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform);
        const b = (navigator.userAgent.includes("Mac") && "ontouchend" in document);
        return a || b;
    },
    isAndroid: () => {
        return navigator.userAgent.toLowerCase().indexOf("android") > -1;
    },
}