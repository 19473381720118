import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { Colors } from '../Resources';

import { Card, Typography, Box, CircularProgress } from '@mui/joy';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  	Experimental_CssVarsProvider as CssVarsProvider,
  	experimental_extendTheme as extendTheme,
} from '@mui/material/styles';
const theme = extendTheme();


export const dataGridSx = {
	width: '100%',
	border: 0,
	'--DataGrid-containerBackground': 'transparent',
	'& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
	'& .MuiDataGrid-columnHeader:focus': { outline: 0 },
	'& .MuiDataGrid-columnHeaders:hover .MuiDataGrid-columnSeparator': { visibility: 'hidden' },
	'& .MuiDataGrid-cell': { backgroundColor: 'white' },
	'& .MuiDataGrid-cell:focus': { outline: 0 },
	'& .MuiDataGrid-footerContainer': { justifyContent: 'end' },
	'& .MuiDataGrid-selectedRowCount': { display: 'none' },
}

interface WorkspaceDataProps {
	data?: Array<Object>;
	config: Array<GridColDef>;
	loading?: boolean;
}

const WorkspaceData: React.FC<WorkspaceDataProps> = ({ data, config, loading = false }) => {
	const { t } = useTranslation('dataFilter');
	return (<>
		<Card sx={{ p: 0, flex: 1, alignItems: 'center', justifyContent: 'center' }}>
			{data && data.length === 0 ?
			<Box>
				<Typography sx={{ color: Colors.black, fontSize: 32, fontWeight: 'bold', letterSpacing: '-0.05rem' }}>{t('noData')}</Typography>
				<Typography sx={{ color: Colors.gray2, fontSize: 16 }}>{t('noDataTips')}</Typography>
			</Box> : 
			data ?
			<CssVarsProvider theme={theme}>
				<DataGrid autoHeight rows={data} columns={config} sx={dataGridSx} />
			</CssVarsProvider> : 
			loading ? 
			<CircularProgress sx={{ my: 4, alignSelf: 'center' }} />
			: <Typography><Trans t={t} i18nKey='getStarted' /></Typography>}
		</Card>
	</>);
}

export default WorkspaceData;


