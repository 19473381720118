import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { AppWorkspaceContext } from './AppWorkspace';

import { Dimens, ReplacesInDoc } from '../Resources';

import Markdown from './MoguaMarkdown';
import { WorkspaceSection } from './WorkspaceSection';


const AppIntegration: React.FC = () => {
	const app = React.useContext(AppWorkspaceContext);
	const [searchParams, setSearchParams] = useSearchParams();
	const chapter = searchParams.get('ct');
	const language = searchParams.get('lg');
	const { t } = useTranslation('docs');
	const docs = t('integration', { returnObjects: true, defaultValue: [] }) as Array<any>;
	const doc = docs.find(e => e.id === chapter) ?? docs[0];
	
	React.useEffect(() => {
		if (chapter) return;
		searchParams.set('ct', docs.at(0)?.id);
		setSearchParams(searchParams);
	}, [chapter, docs, searchParams, setSearchParams]);

	return <WorkspaceSection>
		{doc && <Markdown src={doc.src} replaces={ReplacesInDoc(app)} sx={{ maxWidth: Dimens.maxFocusWidth }}
			defaultLanguage={language ?? undefined} onSelectLanguage={(language: string)=>{
				searchParams.set('lg', language);
				setSearchParams(searchParams);
			}} />}
	</WorkspaceSection>;
}

export default AppIntegration;


