import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { AppWorkspaceContext } from './AppWorkspace';
import Product from '../models/Product';
import { useSettings } from '../models/Settings';
import { contactUrl } from '../Resources';

import PriceCard from './PriceCard';
import { WorkspaceSection } from './WorkspaceSection';
import { NotifyExpired } from './Notifications';
import { Typography, Box, Divider, Button, Link } from '@mui/joy';
import { Modal, ModalClose, ModalOverflow, ModalDialog, DialogTitle, DialogContent } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faBan } from '@fortawesome/free-solid-svg-icons';

import {loadStripe} from '@stripe/stripe-js';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
const stripePromise = loadStripe('pk_live_51OyRxW2KLip9oDs6tGVrzlOVgnSkkcH30otD4rATFP4MbkREEX252HcMIwRsMaoLX9m8wv5xlw3nySFn5cNWDs7p0016jJ4eLn');

const AppSubscription: React.FC = () => {
    const app = React.useContext(AppWorkspaceContext)!;
    const [stripeOptions, setStripeOptions] = React.useState<Object>();
    const [cancelSubs, setCancelSubs] = React.useState<boolean>(false);
    const [cancelling, setCancelling] = React.useState<boolean>(false);
    const { t } = useTranslation('dashboard');
    const { lang } = useParams();
    const settings = useSettings();

    const readOnlyTips = app.readOnly ? <Typography fontSize='sm' variant='soft' color='warning' sx={{ alignSelf: 'start', mb: 2 }}>{t('readOnlyTips')}</Typography> : undefined;

    return (<>
        {app.isExpired() && <NotifyExpired />}
        <WorkspaceSection title={t('sidebar.subscription')}>
            {readOnlyTips}
            <Box display='flex' flexDirection='column' gap={3}>
                <Typography fontWeight='bold' startDecorator={app.subscription ? <FA icon={faArrowsRotate} /> : app.isExpired() ? <FA icon={faBan} /> : undefined}>
                    {app.subscription ? 
                    t('subscriptionRenews', {
                        date: app.subscription.renewAt.format(settings.dateFormat),
                        price: app.subscription.product.price,
                        period: t('product.period' + app.subscription.product.period),
                        interpolation: { escapeValue: false },
                    }) : 
                    app.isExpired() ?
                    t('subscriptionExpired', { date: app.expireAt.format(settings.dateFormat), interpolation: { escapeValue: false } }) : 
                    t('subscriptionExpires', { date: app.expireAt.format(settings.dateFormat), interpolation: { escapeValue: false } })}
                </Typography>
                <Divider sx={{ maxWidth: 600, '--Divider-childPosition': '50%' }}>{t(app.subscription ? 'subscriptionDivider1' : 'subscriptionDivider2')}</Divider>
                <Box display='flex' gap={2} sx={{ maxWidth: 600 }}>
                    {app.subscription ?
                    <PriceCard product={app.subscription.product} sx={{ flex: 0.5 }}>
                        <Button variant='outlined' color='danger' onClick={()=>setCancelSubs(true)}>{t('subscriptionCancel')}</Button>
                    </PriceCard> :
                    Product.all().map(e => <PriceCard key={e.id} product={e} tagColor={e.tag ? '#00BF40' : '#FFAA00'} sx={{ flex: 1 }}>
                        <Button disabled={app.readOnly} onClick={()=>{
                            const options = e.getStripeOptions(app.id, lang, {
                                onSuccess: () => {},
                                onError: (error) => alert(error),
                            });
                            setStripeOptions(options);
                        }}>{t('subscriptionSelect')}</Button>
                    </PriceCard>)}
                </Box>
                <Typography fontSize='xs' color='neutral'>
                    {app.subscription ?
                    <Trans t={t} i18nKey='subscriptionRenewsTips' values={{ date: app.expireAt.format(settings.dateFormat), interpolation: { escapeValue: false } }} /> :
                    app.isExpired() ?
                    <Trans t={t} i18nKey='subscriptionExpiredTips' /> :
                    <Trans t={t} i18nKey='subscriptionExpiresTips' values={{ date: app.expireAt.format(settings.dateFormat), interpolation: { escapeValue: false } }} />}
                </Typography>
                {stripeOptions && <Modal open onClose={()=>setStripeOptions(undefined)}>
                    <ModalOverflow>
                        <ModalDialog variant='soft' sx={{ backgroundColor: 'white', maxWidth: 480, width: '100%' }}>
                            <EmbeddedCheckoutProvider stripe={stripePromise} options={stripeOptions}>
                                <EmbeddedCheckout />
                            </EmbeddedCheckoutProvider>
                        </ModalDialog>
                    </ModalOverflow>
                </Modal>}
                <Modal open={cancelSubs} onClose={()=>setCancelSubs(false)}>
                    <ModalDialog variant='soft' sx={{ maxWidth: 480 }}>
                        <ModalClose variant='plain' sx={{ m: 1 }} />
                        <DialogTitle>😢 {t('subscriptionCancel')}</DialogTitle>
                        <Divider inset='none' />
                        <DialogContent sx={{ my: 2 }}>
                            <Typography><Trans t={t} i18nKey='subscriptionIssueTips' components={[<Link href={contactUrl} />]} /></Typography>
                            <Typography>{t('subscriptionRenewsTips', { date: app.expireAt.format(settings.dateFormat), interpolation: { escapeValue: false } })}</Typography>
                        </DialogContent>
                        <Box display='flex' gap={2}>
                            <Button loading={cancelling} color='danger' sx={{ flex: 1 }} onClick={()=>{
                                setCancelling(true);
                                app.cancelSubscription({
                                    onSuccess: () => {
                                        setCancelling(false);
                                        setCancelSubs(false);
                                    },
                                    onError: (error) => {
                                        setCancelling(false);
                                        alert(error);
                                    },
                                })
                            }}>{t('subscriptionConfirmCancel')}</Button>
                            <Button color='neutral' sx={{ flex: 1 }} onClick={()=>setCancelSubs(false)}>{t('subscriptionDismissCancel')}</Button>
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        </WorkspaceSection>
    </>);
}

export default AppSubscription;


