import * as React from 'react';

import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import syntaxHighlighterStyle from './SyntaxHighlighterStyle';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { QRCodeSVG } from 'qrcode.react';
import { AppAuthConfig, AppDownloadConfig, AppSchemeConfig, AppInfoPlistMockup, AppCapabilityMockup, AppTestConfig } from './AppConfiguration';

import { Colors } from '../Resources';

import { Typography, Divider, Link, Box, BoxProps, CircularProgress, Chip } from '@mui/joy';
import { Card, CardOverflow, CardContent, CardProps } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';


const MatchCmd = (line: string) => {
    if (!line.startsWith("[//]: # (") || !line.endsWith(")")) {
        return { s: false }
    }
    var cmd = line.slice(9, line.length - 1);
    var cmdGroups: { [id: string]: string } = {};
    var currentkey = "";
    var currentvalue = "";
    var trans = false;
    var inValue = false;
    var quotes = "";
    var inQuotes = false;
    for (let i = 0; i < cmd.length; ++i) {
        let c = cmd[i];
        if (!inValue) {
            if (c === "=") {
                inValue = true;
            }
            else if (c === ",") {
                cmdGroups[currentkey.trim()] = "";
                currentkey = "";
            }
            else {
                currentkey += c;
            }
        }
        else if (!inQuotes) {
            if (c === ",") {
                cmdGroups[currentkey.trim()] = currentvalue.trim();
                currentkey = "";
                currentvalue = "";
                inValue = false;
            }
            else if (c === '"' || c === "'") {
                inQuotes = true;
                quotes = c;
            }
        }
        else {
            if (trans) {
                trans = false;
                currentvalue += c;
            }
            else if (c === "\\") {
                trans = true;
            }
            else if (c === quotes) {
                inQuotes = false;
            }
            else {
                currentvalue += c;
            }
        }
    }
    if (inQuotes || trans) {
        return { s: false }
    }
    if (currentkey !== "") {
        cmdGroups[currentkey.trim()] = currentvalue.trim();
    }
    return { s: true, c: cmdGroups }
}


interface MoguaMarkdownProps extends BoxProps {
    src?: string;
    content?: string;
    replaces?: Map<string, string>;
    fromTag?: string;
    toTag?: string;
    defaultLanguage?: string;
    onSelectLanguage?: (language: string) => void;
}

type Segment = {
    type: string; //'string' | 'AppConfig' | 'InfoPlistMockup' | 'CapabilityMockup' | 'qrcode' | 'codeSnippets';
    object?: any;
}

const MoguaMarkdown: React.FC<MoguaMarkdownProps> = ({ src, content, replaces, fromTag, toTag, defaultLanguage, onSelectLanguage, ...props }) => {
    const [items, setItems] = React.useState<Array<React.ReactNode>>();
    const assemble = React.useCallback((content: string, replaces?: Map<string, string>) => {
        let c = content;
        replaces?.forEach((v: string, k: string, m: any) => c = c.replaceAll(k, v));
        let lines = c.split('\n');
        let segments = new Array<Segment>();
        let include = fromTag === undefined;

        for (let i = 0; i < lines.length; i++) {
            let line = lines[i];
            let cmdinfo = MatchCmd(line);
            if (!cmdinfo['s']) {
                if (!include) {
                    continue;
                }
                let last = segments.at(segments.length - 1);
                if (last?.type === 'string') {
                    last.object += '\n' + line;
                } else {
                    segments.push({ type: 'string', object: line });
                }
            } else if (cmdinfo.c != null) {
                if (fromTag !== undefined && (fromTag in cmdinfo.c)) {
                    include = true;
                    continue;
                }
                if (toTag !== undefined && (toTag in cmdinfo.c)) {
                    include = false;
                    continue;
                }
                if (!include) {
                    continue;
                }
                if ('redundant' in cmdinfo.c) {
                    i++;
                    continue;
                }
                if ('appConfig' in cmdinfo.c) {
                    let android = cmdinfo.c['android'] === 'true';
                    let ios = cmdinfo.c['ios'] === 'true';
                    segments.push({ type: cmdinfo.c['appConfig'], object: { android: android, ios: ios } });
                    continue;
                }
                if ('appMockup' in cmdinfo.c) {
                    segments.push({ type: cmdinfo.c['appMockup'] });
                    continue;
                }
                if ('liveDemo' in cmdinfo.c) {
                    segments.push({ type: 'liveDemo' });
                    continue;
                }
                if ('qrcode' in cmdinfo.c) {
                    let tips = cmdinfo.c['tips'];
                    let url = cmdinfo.c['url'];
                    segments.push({ type: 'qrcode', object: { tips: tips, url: url } });
                    continue;
                }
                if (!('language' in cmdinfo.c) && !('target' in cmdinfo.c)) {
                    continue;
                }
                if (!lines[i + 1].startsWith('```')) {
                    continue;
                }
                for (let j = i + 2; j < lines.length; j++) {
                    if (!lines[j].startsWith('```')) {
                        continue;
                    }
                    let target = cmdinfo.c['target'] ?? '';
                    let language = cmdinfo.c['language'] ?? '';
                    let code = lines.slice(i + 1, j + 1).join('\n');
                    let snippet = { target: target, language: language, code: code };
                    let last = segments.at(segments.length - 1);
                    if (last?.type === 'codeSnippets') {
                        (last.object as Array<CodeSnippet>).push(snippet);
                    } else {
                        segments.push({ type: 'codeSnippets', object: [snippet] });
                    }
                    i = j;
                    break;
                }
            }
        }
        let d = Date.now();
        setItems(segments.map((e, i, s) =>
            e.type === 'string' ?
            <MarkdownPreset key={d + i}>{e.object}</MarkdownPreset> :
            e.type === 'AppAuthConfig' ?
            <Card key={d + i} ><AppAuthConfig android={e.object.android} ios={e.object.ios} /></Card> :
            e.type === 'AppDownloadConfig' ?
            <Card key={d + i}><AppDownloadConfig android={e.object.android} ios={e.object.ios} /></Card> :
            e.type === 'AppSchemeConfig' ?
            <Card key={d + i}><AppSchemeConfig android={e.object.android} ios={e.object.ios} /></Card> :
            e.type === 'AppInfoPlistMockup' ?
            <AppInfoPlistMockup key={d + i} /> :
            e.type === 'AppCapabilityMockup' ?
            <AppCapabilityMockup key={d + i} /> :
            e.type === 'qrcode' ?
            <React.Fragment key={d + i}>
                <Typography my={2}>{e.object.tips}</Typography>
                <QRCodeSVG value={e.object.url} />
            </React.Fragment> :
            e.type === 'liveDemo' ?
            <Card key={d + i} sx={{ maxWidth: 480 }}><AppTestConfig /></Card> :
            <CodeCard key={d + i} snippets={e.object} defaultLanguage={defaultLanguage} onSelectLanguage={onSelectLanguage} />));
    }, [fromTag, toTag, defaultLanguage, onSelectLanguage]);

    React.useEffect(() => {
        if (src) {
            fetch(src).then(res => res.text()).then(text => assemble(text, replaces));
        } else if (content) {
            assemble(content, replaces);
        }
    }, [src, content, replaces, defaultLanguage, assemble]);

    return items === undefined ?
        <CircularProgress sx={{ my: 4, alignSelf: 'center' }} /> :
        <Box display='flex' flexDirection='column' {...props}>{items}</Box>;
}

export default MoguaMarkdown;


interface CodeCardProps extends CardProps {
    snippets: Array<CodeSnippet>;
    defaultLanguage?: string;
    onSelectLanguage?: (language: string) => void;
}

type CodeSnippet = {
    target: string,
    language: string,
    code: string,
}

const CodeCard: React.FC<CodeCardProps> = ({ snippets, defaultLanguage, onSelectLanguage }) => {
    const defaultIndex = snippets.findIndex(e => e.language === defaultLanguage);
    const [index, setIndex] = React.useState<number>(defaultIndex === -1 ? 0 : defaultIndex);
    const code = snippets[index].code;
    return (<Card sx={{ p: 0, gap: 0, overflow: 'hidden' }}>
        <CardOverflow sx={{ backgroundColor: 'black', px: 2, py: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
            <Typography fontSize='sm' sx={{ color: 'white' }}>{snippets[index].target}</Typography>
            <div style={{ flex: 1 }} />
            {snippets.map((e, i, s) => {
                const isMe = i === index;
                return e.language && e.language !== '' && <Link
                    key={i}
                    underline='none'
                    sx={{ fontSize: 14, color: isMe && snippets.length > 1 ? 'white' : '#aaa', fontWeight: isMe ? 'bold' : undefined }}
                    onClick={()=>{
                        setIndex(i);
                        onSelectLanguage && onSelectLanguage(e.language);
                    }}>{e.language}</Link>
            })}
            <Divider orientation="vertical" sx={{ '--Divider-lineColor': '#333' }} />
            <Link
                underline='none'
                sx={{ color: 'white' }}
                onClick={()=>navigator.clipboard.writeText(code.replaceAll(/^```.*$/gm, ''))}><FA icon={faCopy} /></Link>
        </CardOverflow>
        <CardContent sx={{ 'pre': { margin: 0 } }}>
            <MarkdownPreset>{code}</MarkdownPreset>
        </CardContent>
    </Card>);
}

const MarkdownPreset: React.FC<{ children: string }> = ({ children }) => {
    return <Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} components={{
        h2({ className, children, ...props }: any) {
            return <Typography fontSize={32} fontWeight='bold' my={1} sx={{ letterSpacing: '-0.05rem' }}>{children}</Typography>;
        },
        h3({ className, children, ...props }: any) {
            let num;
            if (typeof children === 'string') {
                let match = children.match(/^[0-9]+\. /);
                if (match) {
                    let s = match[0];
                    num = s.replace('. ', '');
                    children = children.replace(s, '');
                }
            }
            return <Typography
                startDecorator={num && <Chip
                    size='lg' variant='outlined' color='neutral' component='span'
                    sx={{ '.MuiChip-label': { overflow: 'visible', minInlineSize: 'auto', fontWeight: 'bold', fontColor: Colors.black }, width: 30, height: 30 }}>
                    {num}</Chip>}
                fontSize={24} fontWeight='bold' my={1}>{children}</Typography>;
        },
        h4({ className, children, ...props }: any) {
            return <Typography fontSize={20} fontWeight='bold' my={1}>{children}</Typography>;
        },
        hr({ className, children, ...props }: any) {
            return <Divider sx={{ my: 1 }} />;
        },
        p({ className, children, ...props }: any) {
            return children.length === 1 ? <br /> : <Typography my={1}>{children}</Typography>;
        },
        ol({ className, children, ...props }: any) {
            return <ol className={className} {...props} style={{ padding: '0 1.25rem', margin: '0.5rem 0' }}>{children}</ol>
        },
        code({ className, children, ...props }: any) {
            const match = /language-(\w+)/.exec(className || '');
            const isCommandLine = match && (match[1] === 'bash' || match[1] === 'sh');
            return match ?
            <SyntaxHighlighter
                PreTag='div'
                language={match[1]}
                codeTagProps={ isCommandLine ? { style: { marginLeft: 12 } } : undefined }
                showLineNumbers={!isCommandLine}
                lineNumberStyle={{ minWidth: '1.5em' }}
                style={syntaxHighlighterStyle}
                {...props}>{children.trim()}</SyntaxHighlighter> : 
            <code
                className={className}
                style={{ padding: '0.25em 0.5em', fontSize: '0.875rem', fontWeight: 'bold', backgroundColor:'#e3eaf2', borderRadius: 4 }}
                {...props}>{children}</code>;
        },
    }}>{children}</Markdown>
}
