import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Card, Typography } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';


export const NotifyTestMode: React.FC = () => {
	const { t } = useTranslation('dashboard');
	return (<Card variant='outlined' color='warning' sx={{ bgcolor: '#fbf0e3' }}>
		<Typography fontSize='sm' textColor='inherit' startDecorator={<FA icon={faCircleExclamation} />}>
			{t('notificationTestMode')}
		</Typography>
	</Card>);
}

export const NotifyExpired: React.FC = () => {
	const { t } = useTranslation('dashboard');
	return (<Card variant='outlined' color='danger' sx={{ bgcolor: '#fce4e4' }}>
		<Typography fontSize='sm' textColor='inherit' startDecorator={<FA icon={faCircleExclamation} />}>
			{t('notificationSubscriptionExpired')}
		</Typography>
	</Card>);
}