import * as React from 'react';
import { useTranslation } from "react-i18next";
import { useSearchParams } from 'react-router-dom';

import Markdown from './MoguaMarkdown';
import { WorkspaceSection } from './WorkspaceSection';


const AppCompliance: React.FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const chapter = searchParams.get('ct');
	const { t } = useTranslation('docs');
	const docs = t('compliance', { returnObjects: true, defaultValue: [] }) as Array<any>;
	const doc = docs.find(e => e.id === chapter) ?? docs[0];

	React.useEffect(() => {
		if (chapter) return;
		searchParams.set('ct', docs.at(0)?.id);
		setSearchParams(searchParams);
	}, [chapter, docs, searchParams, setSearchParams]);
	
	const replaces = new Map([
		['${brand}', 'Omnimind'],
    	['${website}', 'www.mogua.io'],
	]);

	return <WorkspaceSection>
		{doc && <Markdown src={doc.src} replaces={replaces} />}
	</WorkspaceSection>;
}

export default AppCompliance;


