import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useParams } from 'react-router-dom';
import { useLangNavigate as useNavigate } from '../modules/i18n';

import { Colors } from '../Resources';

import SEO from '../modules/SEO';
import NavBar from './NavBar';
import Footer from './Footer';
import AppIntegration from './AppIntegration';
import AppCompliance from './AppCompliance';
import { Workspace } from './Workspace';
import { Box, Typography, Divider } from '@mui/joy';
import { List, ListItem, ListItemDecorator, ListItemButton } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import { faCubes, faClipboardCheck, faBook } from '@fortawesome/free-solid-svg-icons'


const Docs: React.FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const chapter = searchParams.get('ct');
	const { page } = useParams();
	const nav = useNavigate();
	const { t } = useTranslation(['dashboard', 'docs']);
	const docsInte = t('integration', { ns: 'docs', returnObjects: true, defaultValue: [] }) as Array<any>;
	const docsComp = t('compliance', { ns: 'docs', returnObjects: true, defaultValue: [] }) as Array<any>;

	return (<>
		<SEO
			title="Mogua | Documentation"
			description="Web to app parameter passing solution. Track Android and iOS apps' installation from web referrals with our attribution SDK."
			url="https://www.mogua.io/docs" />
		<NavBar wide />
		<Divider />
		<Workspace sidebar={
			<List size='sm'>
				<ListItem sx={{ fontWeight: 'bold' }}><ListItemDecorator><FA icon={faCubes} /></ListItemDecorator>{t('sidebar.integration')}</ListItem>
				{docsInte.map(e => <ListItemButton key={e.id} selected={page === 'integration' && chapter === e.id} onClick={()=>{
					const params = searchParams;
                    params.set('ct', e.id);
					const query = Array.from(params.entries()).map(e => e[0] + '=' + e[1]).join('&');
					nav(`/docs/integration?${query}`);
				}}><ListItemDecorator />{e.title}</ListItemButton>)}
				<Divider sx={{ my: 2 }} />
				<ListItem sx={{ fontWeight: 'bold' }}><ListItemDecorator><FA icon={faClipboardCheck} /></ListItemDecorator>{t('sidebar.compliance')}</ListItem>
				{docsComp.map(e => <ListItemButton key={e.id} selected={page === 'compliance' && chapter === e.id} onClick={()=>{
					const params = searchParams;
                    params.set('ct', e.id);
					const query = Array.from(params.entries()).map(e => e[0] + '=' + e[1]).join('&');
					nav(`/docs/compliance?${query}`);
				}}><ListItemDecorator />{e.title}</ListItemButton>)}
				<ListItemButton sx={{ fontWeight: 'bold', visibility: 'collapse', lineHeight: 0 }}><ListItemDecorator />ABCDEFGHIJKLMNOPQ</ListItemButton>
			</List>
		}>
			{page === 'integration' ? 
			<AppIntegration /> :
			page === 'compliance' ?
			<AppCompliance /> :
			<Box pl={8} pt={5}>
				<Typography fontSize={32} fontWeight='bold' textColor={Colors.black} startDecorator={<FA icon={faBook} />} sx={{ letterSpacing: '-0.05rem' }}>{t('docsIndexTitle')}</Typography>
				<Typography fontSize={16} textColor={Colors.gray2}>{t('docsIndexSubtitle')}</Typography>
			</Box>}
		</Workspace>
		<Footer />
	</>);
}

export default Docs;
