import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLangNavigate as useNavigate } from '../modules/i18n';
import { useParams } from "react-router-dom";

import User from '../models/User';
import { Colors, Dimens, MoguaSVG, contactUrl } from '../Resources';

import { Box, Link, Button, Divider } from '@mui/joy';
import { ListDivider, ListItemDecorator } from '@mui/joy';
import { Dropdown, Menu, MenuItem, MenuButton } from '@mui/joy';
import { KeyboardArrowRight, ArrowDropDown } from '@mui/icons-material';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import { faRightToBracket, faRightFromBracket, faGear, faWallet } from '@fortawesome/free-solid-svg-icons'


interface NavBarProps {
    wide?: boolean;
    dash?: boolean;
    bgcolor?: string;
    fgcolor?: string;
}

const NavBar: React.FC<NavBarProps> = ({ wide, dash, bgcolor, fgcolor }) => {
    const { t } = useTranslation('navbar');
    const { lang } = useParams();
    const nav = useNavigate();
    const user = User.me();

	const textSx = { color: fgcolor ?? Colors.black, fontSize: 14 };
	const i = <Divider orientation='vertical' sx={{ bgcolor: fgcolor ?? Colors.black, opacity: 0.125, height: '1rem', alignSelf: 'center' }} />;
	const spacer = <div style={{ flex: 1 }} />;
	const logo = <Link underline='none' sx={textSx} onClick={()=>nav('/')}><MoguaSVG color={ fgcolor ?? Colors.blue } /></Link>;
	const dashboard = <Link underline='none' sx={textSx} onClick={()=>nav('/dashboard')}>{t('dashboard')}</Link>;
	const docs = <Link underline='none' sx={textSx} onClick={()=>nav('/docs')}>{t('docs')}</Link>;
	const contact = <Link underline='none' sx={textSx} href={contactUrl}>{t('contact')}</Link>;
	const userMenu = <UserMenu user={user} />;
	const signButton = <Button startDecorator={<FA icon={faRightToBracket} />} onClick={()=>nav('/sign-in')}>{t('signIn')}</Button>;
	const dashButton = <Button endDecorator={<KeyboardArrowRight />} onClick={()=>nav('/dashboard')} sx={{ pr: "10px", "--Button-gap": "4px" }}>{t('dashboard')}</Button>;

	let navBar;
	if (dash) {
		navBar = <>{logo}{dashboard}{spacer}{contact}{i}{userMenu}</>
	} else if (lang === 'zh') {
        const s1 = <Link underline='none' sx={textSx} onClick={()=>nav('/?s=1')}>{t('productS1')}</Link>;
        const s2 = <Link underline='none' sx={textSx} onClick={()=>nav('/?s=2')}>{t('productS2')}</Link>;
        navBar = <>{logo}{s1}{i}{s2}{i}{docs}{spacer}{contact}{i}{user ? dashButton : signButton}</>
    } else {
        const products = <Link underline='none' sx={textSx} onClick={()=>nav('/products')}>{t('products')}</Link>;
		navBar = <>{logo}{products}{i}{docs}{spacer}{contact}{i}{user ? dashButton : signButton}</>
	}

	return <Box component='nav' display='flex' alignItems='center' justifyContent='center' sx={{ bgcolor: bgcolor ?? 'white' }}>
		<Box flex={1} mx={2} my={1} display='flex' flexWrap='wrap' gap={4} sx={{ maxWidth: wide ? null : Dimens.maxWidth }}>{navBar}</Box>
	</Box>;
}

export default NavBar;

const UserMenu: React.FC<{ user?: User }> = ({ user }) => {
    const [open, setOpen] = React.useState(false);
    const handleOpenChange = React.useCallback((event: React.SyntheticEvent | null, isOpen: boolean) => setOpen(isOpen), []);
    const { t } = useTranslation(['navbar', 'common']);
    const nav = useNavigate();

    // let timeoutId: ReturnType<typeof setTimeout>;
    // const openMenu = ()=>{
    //     if (timeoutId) clearTimeout(timeoutId);
    //     setOpen(true);
    // }
    // const closeMenu = ()=>{
    //     if (timeoutId) clearTimeout(timeoutId);
    //     timeoutId = setTimeout(() => setOpen(false), 250);
    // }
    return <Dropdown open={open} onOpenChange={handleOpenChange}>
        <MenuButton
            variant="plain"
            endDecorator={<ArrowDropDown />}
            // onMouseEnter={openMenu}
            // onMouseLeave={closeMenu}
            >{user?.email ?? t('loading', { ns: 'common' })}</MenuButton>
        <Menu
            size="sm"
            sx={{minWidth: 160}}
            // onMouseEnter={openMenu}
            // onMouseLeave={closeMenu}
            >
            <MenuItem onClick={()=>nav('/settings/general')}>
                <ListItemDecorator><FA icon={faGear} /></ListItemDecorator>{t('settings')}
            </MenuItem>
            {/*<MenuItem onClick={()=>{window.location.href="/wallet"}}>
                <ListItemDecorator><FA icon={faWallet} /></ListItemDecorator>{t("MyWallet")}
            </MenuItem>*/}
            <ListDivider inset="gutter" />
            <MenuItem onClick={()=>user?.signOut({
                onSuccess: () => nav('/sign-in'),
                onError: (error) => alert(error),
            })}>
                <ListItemDecorator><FA icon={faRightFromBracket} /></ListItemDecorator>{t('signOut')}
            </MenuItem>
        </Menu>
    </Dropdown>;
}