import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLangNavigate as useNavigate } from '../modules/i18n';

import User from '../models/User';

import { Utils } from '../GlobalConfig';

import SEO from '../modules/SEO';
import { HeroCard } from './HeroCard';
import { CircularProgress, Typography, Button } from '@mui/joy';


const ConfirmEmail: React.FC = () => {
	const { t } = useTranslation('account');
	const [verified, setVerified] = React.useState<boolean>(false);
	const [error, setError] = React.useState<string | null>(null);

	const [searchParams, setSearchParams] = useSearchParams();
    const verifyKey = searchParams.get("_verify_key");

    const nav = useNavigate();

    React.useEffect(() => {
		User.verifyEmail(verifyKey!, true, {
			onSuccess: () => setVerified(true),
			onError: (error) => setError(error),
		});
	}, [verifyKey]);

	const isMobile = Utils.isMobile();
	const size = isMobile ? 'lg' : 'md';
	
	if (!verified) {
		return error == null ?
		<HeroCard title={t('verifyTitleLoading')}><CircularProgress /></HeroCard> :
		<HeroCard title={t('verifyTitleFailed')}><Typography>{error}</Typography></HeroCard>;
	} else {
		return (<>
			<SEO
				title="Mogua Sign up | Sign up to create account"
				description="Sign up to create your Mogua account. Start for free, no credit card required."
				url="https://www.mogua.io/confirm-email" />
			<HeroCard title={t('verifyTitleSuccess')}>
				<Typography>{t('verifyTipsSuccess')}</Typography>
				<Button size={size} onClick={()=>nav('/dashboard')}>{t('gotoDashboard')}</Button>
	        </HeroCard>
		</>);
	}
}

export default ConfirmEmail;


