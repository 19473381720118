import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useLangNavigate as useNavigate } from '../modules/i18n';
import { useParams } from "react-router-dom";

import Product from '../models/Product';
import { Colors, Dimens, moguaDemoAppKey } from '../Resources';
import { AndroidSVG, AppleSVG, FlutterSVG, UnitySVG, CocosSVG } from '../Resources';

import SEO from '../modules/SEO';
import NavBar from './NavBar';
import Footer from './Footer';
import HomeZh from './HomeZh';
import LiveDemoForm from './LiveDemoForm';
import Markdown from './MoguaMarkdown';
import PriceCard from './PriceCard';
import { SectionIntro } from './SectionIntro';
import { Box, Typography, Button, Stack, Chip, Divider, Card } from '@mui/joy';
import { List, ListItem } from '@mui/joy';
import { CheckCircleRounded, MoreHorizRounded } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleRight, faBook, faListCheck, faAngleRight } from '@fortawesome/free-solid-svg-icons'


const Home: React.FC = () => {
	const { lang } = useParams();
	if (lang === 'zh') {
		return <HomeZh />;
	}
	return <>
		<SEO
			title="Mogua: Web to app attribution solution"
			description="Web to app parameter passing solution. Track Android and iOS apps' installation from web referrals with our attribution SDK."
			url="https://www.mogua.io" />
		<NavBar />
		<Banner />
		<Section1 />
		<Section2 />
		<Section3 />
		<Footer />
	</>;
}

export default Home;

const Banner: React.FC = () => {
	const { t } = useTranslation('marketing');
	const nav = useNavigate();
	return (
		<Box display='flex' alignItems='center' justifyContent='center' sx={{
			'backgroundColor': '#000',
			'backgroundImage': 'linear-gradient(270deg, rgba(0,0,0,0), rgba(0, 0, 0, 0.8)), url(https://www.mogua.io/images/homepage2.jpg)',
			'backgroundRepeat': 'no-repeat',
			'backgroundPosition': 'center',
			'backgroundSize': 'cover',
		}}>
			<Box overflow='hidden' sx={{ flex: 1, mx: 2.5, my: 9, ...Dimens.autoContentWidth }}>
				<Typography sx={{ color: Colors.blue, fontSize: 32, fontWeight: 'bold' }}>{t('slogan3')}</Typography>
				<Typography sx={{ color: Colors.white, fontSize: 72, fontWeight: 'bold', lineHeight: 1.1, letterSpacing: '-0.15rem' }}><Trans t={t} i18nKey='slogan1' /></Typography>
				<Typography sx={{ color: Colors.white, fontSize: 20, lineHeight: 1.3, mt: 2.5, maxWidth: '40%' }}>{t('slogan2')}</Typography>
				<Button size="lg" endDecorator={<FontAwesomeIcon icon={faCircleRight} />} sx={{ mt: 5 }} onClick={()=>nav('/sign-up')}>{t('bannerButton')}</Button>
			</Box>
		</Box>
	);
}


const Section1: React.FC = () => {
	const { t } = useTranslation('marketing');
	return <Box display='flex' alignItems='center' justifyContent='center' sx={{ bgcolor: Colors.grayBG, py: 7.5 }}>
		<Stack 
			direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row'}}
			alignItems={{ xs: 'center', sm: 'center', md: 'flex-start' }}
			justifyContent="center"
			spacing={10} sx={{ ...Dimens.autoContentWidth }}>
			<Card size="lg">
				<Typography sx={{ color: Colors.black, fontWeight: 'bold' }}>{t('section1Subtitle')}</Typography>
				<LiveDemoForm appKey={moguaDemoAppKey} appName='Mogua Demo' appIcon='https://mogua-us.oss-us-west-1.aliyuncs.com/public/110cbcffd017.1713857260.png' />			
			</Card>
			<SectionIntro title={t('section1Title')} desc={t('section1Desc')} />
		</Stack>
	</Box>;
}


const Section2: React.FC = () => {
	const { t } = useTranslation('marketing');
	const nav = useNavigate();
	return <Box display='flex' alignItems='center' justifyContent='center' sx={{ py: 7.5 }}>
		<Stack
			direction={{ sm: 'column', md: 'row' }}
			alignItems='center'
			justifyContent='center'
			spacing={{ xs: 10, sm: 10, md: 10 }} sx={{ ...Dimens.autoContentWidth }}>
			<Box>
				<SectionIntro title={t('section2Title')} desc={t('section2Desc')} />
				<Button startDecorator={<FontAwesomeIcon icon={faBook} />} sx={{ mt: '30px' }} onClick={()=>nav('/docs')}>{t('section2Button')}</Button>
				<Stack direction='row' spacing={2} mt={4}>
					<Chip variant='plain' startDecorator={<AndroidSVG />}>Android</Chip>
					<Chip variant='plain' startDecorator={<AppleSVG />}>iOS</Chip>
					<Chip variant='plain' startDecorator={<FlutterSVG />}>Flutter</Chip>
					<Chip variant='plain' startDecorator={<UnitySVG />}>Unity</Chip>
					<Chip variant='plain' startDecorator={<CocosSVG />}>Cocos2d</Chip>
				</Stack>
			</Box>
			<Markdown src='/code.md' sx={{ maxWidth: 640 }} />
		</Stack>
	</Box>;
}


const Section3: React.FC = () => {
	const { t } = useTranslation('marketing');
	const nav = useNavigate();
	const checkIcon = <CheckCircleRounded color='success' />
	return <Box display='flex' alignItems='center' justifyContent='center' sx={{ bgcolor: Colors.grayBG, py: 5 }}>
		<Stack alignItems='center' justifyContent='center' spacing={3} sx={{ ...Dimens.autoContentWidth }}>
			<Stack direction={{ sm: 'column', md: 'row' }} spacing={{ xs: 3, sm: 3, md: 10 }} sx={{ width: '100%' }}>
				<SectionIntro title={t('section3Title')} desc={t('section3Desc')} />
				<Box sx={{ flex: 1 }}>
					<Typography startDecorator={<FontAwesomeIcon icon={faListCheck} />} sx={{
						color: Colors.black,
						fontWeight: 'bold',
						mb: 2 }}>{t('section3Subtitle')}</Typography>
					<Card>
						<List>
							<ListItem>{checkIcon}{t('section3List1')}</ListItem>
							<ListItem>{checkIcon}{t('section3List2')}</ListItem>
							<ListItem>{checkIcon}{t('section3List3')}</ListItem>
							<ListItem>{checkIcon}{t('section3List4')}</ListItem>
							<ListItem><MoreHorizRounded />{t('section3List5')}</ListItem>
						</List>
					</Card>
				</Box>
			</Stack>
			<Divider />
			<Stack direction={{ sm: 'column', md: 'row' }} spacing={{ xs: 2, sm: 2 }} sx={{ width: '100%' }}>
				<PriceCard digit={t('freeCardTitle')} major={t('freeCardSubtitle')} sx={{ flex: 1 }}>
					<Typography ml={2} sx={{ color: Colors.greenFG }}><Trans t={t} i18nKey='freeCardDesc' /></Typography>
				</PriceCard>
				{Product.all().map(e => <PriceCard key={e.id} product={e} tagColor={e.tag ? '#00BF40' : '#FFAA00'} sx={{ flex: 1 }}/>)}
			</Stack>
			<Box display='flex' alignItems='center' justifyContent='center'>
				<Button endDecorator={<FontAwesomeIcon icon={faAngleRight} />} sx={{ my: 2 }} onClick={()=>nav('/sign-up')}>{t('section3Button')}</Button>
			</Box>
		</Stack>
	</Box>;
} 


