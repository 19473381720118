import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useLangNavigate as useNavigate } from '../modules/i18n';

import { AppWorkspaceContext } from './AppWorkspace';
import { Colors, Dimens, ReplacesInDoc, timezones } from '../Resources';

import AppIcon from './AppIcon';
import IpEdit from './IpEdit';
import TipsCard from './TipsCard';
import Markdown from './MoguaMarkdown';
import { EditInput } from './MoguaInput';
import { WorkspaceSection } from './WorkspaceSection';
import { NotifyExpired } from './Notifications';
import LiveDemoForm from './LiveDemoForm';

import { Card, Typography, Box, ButtonGroup, Button, IconButton, Link, Input, styled, Divider, LinearProgress } from '@mui/joy';
import { Modal, ModalOverflow, ModalDialog, DialogTitle } from '@mui/joy';
import { Table, TableProps } from '@mui/joy';
import { Select, Option } from '@mui/joy';
import { UnfoldMore, KeyboardArrowRight } from '@mui/icons-material';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faCopy, faGear, faCircleQuestion, faCloudArrowUp, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faAngleDown, faGlobe, faTrashCan, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';


const AppConfiguration: React.FC = () => {
    let app = React.useContext(AppWorkspaceContext)!;
    let [timezoneStatus, setTimezoneStatus] = React.useState<any | undefined>();
    let [openSchemeTips, setOpenSchemeTips] = React.useState<boolean>(false);
    let [openUlinkTips, setOpenUlinkTips] = React.useState<boolean>(false);
    let [openTeamIdTips, setOpenTeamIdTips] = React.useState<boolean>(false);
    let { t } = useTranslation('dashboard');
    let readOnlyTips = React.useMemo(()=>{
        return app.readOnly ? <Typography fontSize='sm' variant='soft' color='warning' sx={{ alignSelf: 'start', mb: 2 }}>{t('readOnlyTips')}</Typography> : undefined;
    }, [app.readOnly, t]); ;
    let onTimezoneStatusChanged = React.useCallback((obj: any)=>setTimezoneStatus(obj), []);

    return (<>
        {app.isExpired() && <NotifyExpired />}
        <WorkspaceSection title={t('general')}>
            {readOnlyTips}
            <Table noWrap borderAxis='none' style={{ tableLayout: 'auto' }} sx={{ '& tr td:last-child': { width: '100%' } }}>
                <tbody>
                    <tr>
                        <td><Typography fontSize='sm'>{t('appIcon')}</Typography></td>
                        <td><AppIconEdit /></td>
                    </tr>
                    <tr>
                        <td><Typography fontSize='sm'>{t('appName')}</Typography></td>
                        <td><AppNameEdit /></td>
                    </tr>
                    <tr>
                        <td><Typography fontSize='sm'>{t('appTimezone')}</Typography></td>
                        <td><AppTimeZoneEdit onTimezoneStatusChanged={onTimezoneStatusChanged} /></td>
                    </tr>
                    <tr>
                        <td style={{ height: 0, lineHeight: 0 }}></td>
                        <td style={{ height: 0, lineHeight: 1 }}>
                            {!timezoneStatus
                            ? <Typography fontSize='xs' textColor='#999'>{t('timezoneModifyTips')}</Typography>
                            : <Box display='flex' flexDirection='row' alignItems='center' maxWidth={200} gap={2}>
                                <Typography fontSize='xs' textColor='#999'>{t('timezoneStatus_' + timezoneStatus.status)}</Typography>
                                {timezoneStatus.status !== 'completed' && <LinearProgress size='md' determinate value={timezoneStatus.progress / timezoneStatus.total * 100} sx={{ height: '6px' }} />}
                            </Box>}
                        </td>
                    </tr>
                    <tr style={{ visibility: 'collapse' }}>
                        <td style={{ height: 0, lineHeight: 0, paddingTop: 0, paddingBottom: 0 }}><Typography fontSize='sm'>Android Download URL</Typography></td>
                        <td style={{ height: 0, lineHeight: 0, paddingTop: 0, paddingBottom: 0 }} />
                    </tr>
                </tbody>
            </Table>
        </WorkspaceSection>
        <WorkspaceSection title={t('authentication')}>
            {readOnlyTips}
            <AppAuthConfig android ios />
        </WorkspaceSection>
        <WorkspaceSection title={t('appHosting')}>
            {readOnlyTips}
            <AppDownloadConfig android ios />
        </WorkspaceSection>
        {/*<WorkspaceSection title='Deep Linking'>
            {readOnlyTips}
            <Table noWrap borderAxis='none' style={{ tableLayout: 'auto' }} sx={{ '& tr td:last-child': { width: '100%' } }}>
                <tbody>
                    <tr>
                        <td colSpan={2}>
                            <TipsCard>
                                MoguaSDK will attempt to use <strong>Direct Deep Linking</strong> to open your app and pass parameters.
                                If you do not need this feature or the app is not installed,  MoguaSDK will fallback to these download URLs to initiate the app download.
                                <br />
                                <br />
                                After download and installation, MoguaSDK will use <strong>Deferred Deep Linking</strong> to pass the parameters once the user launches the app.
                            </TipsCard>
                        </td>
                        <td />
                    </tr>
                    <tr><td colSpan={3} style={{ height: 'auto' }} /></tr>
                    <tr>
                        <td><Typography fontSize='sm'>Android Download URL</Typography></td>
                        <td><AppDownloadUrlEdit key='android_download_url' platform='android' /></td>
                        <td />
                    </tr>
                    <tr>
                        <td style={{ height: 'auto' }} />
                        <td style={{ height: 'auto' }}><Typography fontSize='xs' textColor='#999'>e.g., Google Play Store Link, APK Download URL, etc.</Typography></td>
                        <td style={{ height: 'auto' }} />
                    </tr>
                    <tr>
                        <td><Typography fontSize='sm'>iOS Download URL</Typography></td>
                        <td><AppDownloadUrlEdit key='ios_download_url' platform='ios' /></td>
                        <td />
                    </tr>
                    <tr>
                        <td style={{ height: 'auto' }} />
                        <td style={{ height: 'auto' }}><Typography fontSize='xs' textColor='#999'>e.g., Apple AppStore Link.</Typography></td>
                        <td style={{ height: 'auto' }} />
                    </tr>
                    <tr><td colSpan={3} /></tr>
                    <tr>
                        <td colSpan={2}>
                            <TipsCard>
                                The URL Scheme and Universal Link are used for <strong>Direct Deep Linking</strong>.
                                <br />
                                Disregard the following settings if not needed.
                            </TipsCard>
                        </td>
                        <td />
                    </tr>
                    <tr><td colSpan={3} style={{ height: 'auto' }} /></tr>
                    <tr>
                        <td><Typography fontSize='sm'>URL Scheme</Typography></td>
                        <td>
                            <Box display='flex' gap={1}>
                                <Input disabled key='ios_scheme' defaultValue={app.deepLinking.scheme} sx={{ width: Dimens.inputWidth }} />
                                <Button disabled={app.readOnly} variant='outlined' color='neutral' startDecorator={<FA icon={faGear} />}
                                    onClick={()=>setOpenSchemeTips(true)}>In-App</Button>
                            </Box>
                        </td>
                        <td />
                    </tr>
                    <tr>
                        <td><Typography fontSize='sm'>Universal Link</Typography></td>
                        <td>
                            <Box display='flex' gap={1}>
                                <Input slotProps={app.deepLinking.teamId.length > 0 ? undefined : { input: { style: { color: 'transparent', textShadow: '0 0 6px rgba(0,0,0,0.25)' } } }} 
                                    disabled key='ios_universal' defaultValue={'applinks:' + app.deepLinking.universalLink} sx={{ width: Dimens.inputWidth }} />
                                <Button disabled={app.readOnly || app.deepLinking.teamId === ''} variant='outlined' color='neutral' startDecorator={<FA icon={faGear} />}
                                    onClick={()=>setOpenUlinkTips(true)}>In-App</Button>
                            </Box>
                        </td>
                        <td />
                    </tr>
                    <tr>
                        <td style={{ height: 'auto' }} />
                        <td style={{ height: 'auto' }}><Typography fontSize='xs' textColor='#999'>You need to provide Team ID to enable the universal link (For iOS only).</Typography></td>
                        <td style={{ height: 'auto' }} />
                    </tr>
                    <tr>
                        <td><Typography fontSize='sm'><Link endDecorator={<FA icon={faCircleQuestion} />} onClick={()=>setOpenTeamIdTips(true)}>Team ID</Link></Typography></td>
                        <td><TeamIdEdit key='ios_team' /></td>
                        <td />
                    </tr>
                </tbody>
            </Table>
            <SchemeTips open={openSchemeTips} onClose={()=>setOpenSchemeTips(false)} />
            <UlinkTips open={openUlinkTips} onClose={()=>setOpenUlinkTips(false)} />
            <TeamIdTips open={openTeamIdTips} onClose={()=>setOpenTeamIdTips(false)} />
        </WorkspaceSection>*/}
        <WorkspaceSection title={t('ipWhitelist')}>
            {readOnlyTips}
            <IpEdit />
        </WorkspaceSection>
    </>);
}

export default AppConfiguration;


const MuiHiddenInput = styled('input')`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
`;

const AppIconEdit: React.FC = () => {
    const app = React.useContext(AppWorkspaceContext)!;
    const [appName, setAppName] = React.useState<string>(app.name);
    const [file, setFile] = React.useState<any>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const { t } = useTranslation(['dashboard', 'common']);

    React.useEffect(() => app.addListener(() => setAppName(app.name)), [app]);

    return (<Box display='flex' gap={1}>
        <AppIcon size={34} alt={appName} src={file ? URL.createObjectURL(file) : app.icon} />
        {file ? 
        <ButtonGroup variant='outlined' color='neutral'>
            <Button loading={loading} onClick={()=>{
                const formData = new FormData();
                formData.append('file', file);
                setLoading(true);
                app.changeIcon(formData, {
                    onSuccess: () => {
                        setLoading(false);
                        setFile(null);
                    },
                    onError: (error) => {
                        setLoading(false);
                        setFile(null);
                        alert(error);
                    },
                });
            }}>{t('save', { ns: 'common' })}</Button>
            <IconButton disabled={loading} onClick={()=>{
                setFile(null);
            }}><FA icon={faXmark} /></IconButton>
        </ButtonGroup> : 
        <Button disabled={app.readOnly} variant='outlined' color='neutral' component='label' startDecorator={<FA icon={faCloudArrowUp} />}>
            {t('upload', { ns: 'common' })}<MuiHiddenInput type='file' accept='.jpg, .jpeg, .png' onChange={(event)=>{
                if (!event.target.files || event.target.files.length === 0) return;
                const file = event.target.files[0];
                if (file.size > 1024 * 1000 * 5) {
                    alert(t('appIconRule'));
                    return;
                }
                setFile(file);
            }} />
        </Button>}
    </Box>);
}


const AppNameEdit: React.FC = () => {
    const app = React.useContext(AppWorkspaceContext)!;
    const [nameError, setNameError] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const { t } = useTranslation('dashboard');

    return <EditInput
        disabled={app.readOnly}
        variant='outlined' color='neutral' defaultValue={app.name} loading={loading} errMsg={nameError}
        onChange={()=>setNameError(null)}
        onCancel={()=>setNameError(null)}
        onSave={(name: string, complete: ()=>void)=>{
            // Todo: refined the name regex
            const nameReg = /^.{2,20}$/;
            if (nameReg.test(name) === false) {
                setNameError(t('appNameRule'));
                return;
            }
            setLoading(true);
            app.changeName(name, {
                onSuccess: () => {
                    setLoading(false);
                    complete();
                },
                onError: (error) => {
                    setLoading(false);
                    alert(error);
                },
            });
        }}
    />;
}

const AppTimeZoneEdit: React.FC<{ onTimezoneStatusChanged?: (status: any)=>void }> = ({ onTimezoneStatusChanged }) => {
    let app = React.useContext(AppWorkspaceContext)!;
    let [timezone, setTimezone] = React.useState(app.timezone);
    let [confirmTimezone, setConfirmTimezone] = React.useState<string | undefined>();
    let [loading, setLoading] = React.useState(app.timezoneStatus !== 'completed');
    let { t } = useTranslation(['dashboard', 'common']);

    let checkTimezoneStatus = React.useCallback(()=>{
        setLoading(true);
        app.checkTimezoneStatus({
            onSuccess: (obj)=>{
                onTimezoneStatusChanged && onTimezoneStatusChanged(obj);
                if (obj.status === 'completed') {
                    setLoading(false);
                    return;
                }
                setTimeout(checkTimezoneStatus, 3000);
            },
            onError: (error)=>{
                setLoading(false);
                alert(error);
            },
        });
    }, [app, onTimezoneStatusChanged]);

    React.useEffect(()=>{
        if (app.timezoneStatus === 'completed') return;
        checkTimezoneStatus();
    }, [app, checkTimezoneStatus]);

    return <form onSubmit={(event)=>{
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const timezone = data.get('timezone') as string;
        setConfirmTimezone(timezone);
    }}>
        <Box display='flex' flexDirection='row' gap={1}>
            <Select key={timezone} defaultValue={timezone} disabled={app.readOnly||loading} name='timezone' size='sm' sx={{ width: 'max-content' }}
                onChange={(event, value)=>setTimezone(value!)}>
                {timezones.map(e=><Option key={e.label} value={e.value}>{e.label}</Option>)}
            </Select>
            {timezone !== app.timezone && <ButtonGroup>
                <Button type='submit' loading={loading}>{t('save', { ns: 'common' })}</Button>
                <IconButton disabled={loading} onClick={()=>setTimezone(app.timezone)}><FA icon={faXmark} /></IconButton>
            </ButtonGroup>}
        </Box>
        <Modal open={confirmTimezone !== undefined} onClose={()=>setConfirmTimezone(undefined)}>
            <ModalDialog variant='soft' sx={{ maxWidth: Dimens.maxDialogWidth }}>
                <DialogTitle>{t('timezoneChangeTitle')}</DialogTitle>
                <Typography mb={2} fontSize='md'>
                    <Trans t={t} i18nKey='timezoneChangeDesc' values={{ timezone: timezones.find(e=>e.value===confirmTimezone)?.label, interpolation: { escapeValue: false } }} />
                </Typography>
                <Box display='flex' flexDirection='row' gap={2}>
                    <Button variant='outlined' color='neutral' onClick={()=>setConfirmTimezone(undefined)} sx={{ flex: 1 }}>{t('timezoneChangeDismiss')}</Button>
                    <Button variant='solid' onClick={()=>{
                        setConfirmTimezone(undefined);
                        setLoading(true);
                        app.changeTimezone(timezone, {
                            onSuccess: () => checkTimezoneStatus(),
                            onError: (error) => {
                                setLoading(false);
                                alert(error);
                            },
                        });
                    }} sx={{ flex: 1 }}>{t('timezoneChangeConfirm')}</Button>
                </Box>
            </ModalDialog>
        </Modal>
    </form>;
}

const AppIdEdit: React.FC<{ platform: string }> = ({ platform }) => {
    const app = React.useContext(AppWorkspaceContext)!;
    const [idError, setIdError] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const { t } = useTranslation('dashboard');

    const defaultValue = app.pkgIds.get(platform);
    
    return <EditInput
        disabled={app.readOnly}
        sx={{ width: Dimens.inputWidth }}
        variant='outlined' color='neutral' defaultValue={defaultValue} loading={loading} errMsg={idError}
        onChange={()=>setIdError(null)}
        onCancel={()=>setIdError(null)}
        onSave={(id: string, complete: ()=>void)=>{
            const idReg = /^([A-Za-z]{1}[A-Za-z\d_-]*\.)*[A-Za-z][A-Za-z\d_-]*$/;
            if (idReg.test(id) === false) {
                setIdError(t('appIdRule'));
                return;
            }
            setLoading(true);
            app.updatePkgId(platform, id, {
                onSuccess: () => {
                    setLoading(false);
                    complete();
                },
                onError: (error) => {
                    setLoading(false);
                    alert(error);
                },
            });
        }}
    />;
}


export const AppAuthConfig: React.FC<{ android?: boolean, ios?: boolean }> = ({ android, ios }) => {
    const app = React.useContext(AppWorkspaceContext);
    if (!app) return <AppConfigInvalid />;
    return <Table noWrap borderAxis='none' style={{ tableLayout: 'auto' }} sx={{ '& tr td:last-child': { width: '100%' } }}>
        <tbody>
            <tr>
                <td><Typography fontSize='sm'>App Key</Typography></td>
                <td>
                    <Box display='flex' gap={1}>
                        <Input disabled defaultValue={app.key} sx={{ width: Dimens.inputWidth }} />
                        <IconButton onClick={()=>navigator.clipboard.writeText(app.key)}
                            disabled={app.readOnly} variant='outlined'><FA icon={faCopy} /></IconButton>
                    </Box>
                </td>
                <td />
            </tr>
            {android && <tr>
                <td><Typography fontSize='sm'>Android App ID</Typography></td>
                <td><AppIdEdit key='android_app_id' platform='android' /></td>
                <td />
            </tr>}
            {ios && <tr>
                <td><Typography fontSize='sm'>iOS Bundle ID</Typography></td>
                <td><AppIdEdit key='ios_bundle_id' platform='ios' /></td>
                <td />
            </tr>}
            <tr style={{ visibility: 'collapse' }}>
                <td style={{ height: 0, lineHeight: 0, paddingTop: 0, paddingBottom: 0 }}><Typography fontSize='sm'>Android Download URL</Typography></td>
                <td style={{ height: 0, lineHeight: 0, paddingTop: 0, paddingBottom: 0 }} />
                <td style={{ height: 0, lineHeight: 0, paddingTop: 0, paddingBottom: 0 }} />
            </tr>
        </tbody>
    </Table>
}


const AppDownloadUrlEdit: React.FC<{ platform: string }> = ({ platform }) => {
    const app = React.useContext(AppWorkspaceContext)!;
    const [urlError, setUrlError] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const { t } = useTranslation('dashboard');

    const defaultValue = app.downloadURLs.get(platform)?.url;

    return <EditInput
        disabled={app.readOnly}
        sx={{ width: Dimens.inputWidth }}
        variant='outlined' color='neutral' defaultValue={defaultValue} loading={loading} errMsg={urlError}
        onChange={()=>setUrlError(null)}
        onCancel={()=>setUrlError(null)}
        onSave={(url: string, complete: ()=>void)=>{
            const urlReg = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
            if (urlReg.test(url) === false) {
                setUrlError(t('appDownloadRule'));
                return;
            }
            setLoading(true);
            app.updateDownloadURL(platform, url, {
                onSuccess: () => {
                    setLoading(false);
                    complete();
                },
                onError: (error) => {
                    setLoading(false);
                    alert(error);
                },
            });
        }}
    />;
}


export const AppDownloadConfig: React.FC<{ android?: boolean, ios?: boolean }> = ({ android, ios }) => {
    const app = React.useContext(AppWorkspaceContext);
    if (!app) return <AppConfigInvalid />;
    return <Table noWrap borderAxis='none' style={{ tableLayout: 'auto' }} sx={{ '& tr td:last-child': { width: '100%' } }}>
        <tbody>
            {android && <tr>
                <td><Typography fontSize='sm'>Android Download URL</Typography></td>
                <td><AppDownloadUrlEdit key='android_download_url' platform='android' /></td>
                <td />
            </tr>}
            {ios && <tr>
                <td><Typography fontSize='sm'>iOS Download URL</Typography></td>
                <td><AppDownloadUrlEdit key='ios_download_url' platform='ios' /></td>
                <td />
            </tr>}
            <tr style={{ visibility: 'collapse' }}>
                <td style={{ height: 0, lineHeight: 0, paddingTop: 0, paddingBottom: 0 }}><Typography fontSize='sm'>Android Download URL</Typography></td>
                <td style={{ height: 0, lineHeight: 0, paddingTop: 0, paddingBottom: 0 }} />
                <td style={{ height: 0, lineHeight: 0, paddingTop: 0, paddingBottom: 0 }} />
            </tr>
        </tbody>
    </Table>
}


const SchemeTips: React.FC<{ open: boolean, onClose: ()=>void }> = ({ open, onClose }) => {
    const app = React.useContext(AppWorkspaceContext)!;
    const [platform, setPlatform] = React.useState<'android' | 'ios'>('android');
    return <Modal open={open} onClose={onClose}>
        <ModalDialog variant='soft' sx={{ maxWidth: Dimens.maxDialogWidth }}>
            <DialogTitle>Configure URL Scheme In App</DialogTitle>
            <Box display='flex' gap={1} my={2}>
                <Button sx={platform === 'android' ? { border: '2px solid ' + Colors.blue, color: Colors.blue } : undefined}
                    variant='outlined' color='neutral' onClick={()=>setPlatform('android')}>Android App</Button>
                <Button sx={platform === 'ios' ? { border: '2px solid ' + Colors.blue, color: Colors.blue } : undefined}
                    variant='outlined' color='neutral' onClick={()=>setPlatform('ios')}>iOS App</Button>
            </Box>
            <Markdown src={`/docs/get-params-open-${platform}.md`} replaces={ReplacesInDoc(app)} fromTag='startScheme' toTag='endScheme' />
        </ModalDialog>
    </Modal>
}


const UlinkTips: React.FC<{ open: boolean, onClose: ()=>void }> = ({ open, onClose }) => {
    const app = React.useContext(AppWorkspaceContext)!;
    return <Modal open={open} onClose={onClose}>
        <ModalDialog variant='soft' sx={{ maxWidth: Dimens.maxDialogWidth }}>
            <DialogTitle>Configure Universal Link In App (iOS Only)</DialogTitle>
            <Markdown src={`/docs/get-params-open-ios.md`} replaces={ReplacesInDoc(app)} fromTag='startUlink' toTag='endUlink' />
        </ModalDialog>
    </Modal>
}


const TeamIdEdit: React.FC = () => {
    const app = React.useContext(AppWorkspaceContext)!;
    const [idError, setIdError] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);

    return <EditInput
        variant='outlined' color='neutral'
        sx={{ width: Dimens.inputWidth }}
        disabled={app.readOnly}
        key={app.deepLinking.teamId}
        defaultValue={app.deepLinking.teamId}
        loading={loading} errMsg={idError}
        onChange={()=>setIdError(null)}
        onCancel={()=>setIdError(null)}
        onSave={(id: string, complete: ()=>void)=>{
            const idReg = /^[A-Za-z\d]{10,}$/;
            if (idReg.test(id) === false) {
                setIdError('Invalid Team ID.');
                return;
            }
            setLoading(true);
            app.updateTeamId(id.toUpperCase(), {
                onSuccess: () => {
                    setLoading(false);
                    complete();
                },
                onError: (error) => {
                    setLoading(false);
                    alert(error);
                },
            });
        }}
    />;
}


const TeamIdTips: React.FC<{ open: boolean, onClose: ()=>void }> = ({ open, onClose }) => {
    const { t } = useTranslation('dashboard');
    return <Modal open={open} onClose={onClose}>
        <ModalOverflow>
            <ModalDialog variant='soft' sx={{ maxWidth: Dimens.maxDialogWidth }}>
                <Typography fontSize='lg' fontWeight='bold'>Team ID</Typography>
                <Typography>
                    <Trans t={t} i18nKey='schemeTeamIdDesc' components={[
                        <Link href='https://developer.apple.com/account' target='_blank' sx={{ fontWeight: 'bold' }}/>
                    ]} />
                </Typography>
                <img style={{ border: '1px solid #ccc' }} alt='' src='/images/locate-team-id-light-en_2x.png' />
            </ModalDialog>
        </ModalOverflow>
    </Modal>
}


export const AppSchemeConfig: React.FC<{ android?: boolean, ios?: boolean }> = ({ android, ios }) => {
    const { t } = useTranslation('dashboard');
    const [openTeamIdTips, setOpenTeamIdTips] = React.useState<boolean>(false);
    const app = React.useContext(AppWorkspaceContext);
    if (!app) return <AppConfigInvalid />;
    return <>
        <Table noWrap borderAxis='none' style={{ tableLayout: 'auto' }} sx={{ '& tr td:last-child': { width: '100%' } }}>
            <tbody>
                {(android || ios) && <tr>
                    <td><Typography fontSize='sm'>URL Scheme</Typography></td>
                    <td>
                        <Box display='flex' gap={1}>
                            <Input disabled key='ios_scheme' defaultValue={app.deepLinking.scheme} sx={{ width: Dimens.inputWidth }} />
                            <IconButton onClick={()=>navigator.clipboard.writeText(app.deepLinking.scheme)} disabled={app.readOnly} variant='outlined'>
                                <FA icon={faCopy} />
                            </IconButton>
                        </Box>
                    </td>
                    <td />
                </tr>}
                {ios && <tr>
                    <td><Typography fontSize='sm'>Universal Link</Typography></td>
                    <td>
                        <Box display='flex' gap={1}>
                            <Input slotProps={app.deepLinking.teamId.length > 0 ? undefined : { input: { style: { color: 'transparent', textShadow: '0 0 6px rgba(0,0,0,0.25)' } } }} 
                                disabled key='ios_universal' defaultValue={'applinks:' + app.deepLinking.universalLink} sx={{ width: Dimens.inputWidth }} />
                            <IconButton onClick={()=>navigator.clipboard.writeText(app.deepLinking.universalLink)} disabled={app.readOnly} variant='outlined'>
                                <FA icon={faCopy} />
                            </IconButton>
                        </Box>
                    </td>
                    <td />
                </tr>}
                {ios && <tr>
                    <td style={{ height: 'auto' }} />
                    <td style={{ height: 'auto' }}><Typography fontSize='xs' textColor='#999'>{t('schemeTeamIdTips')}</Typography></td>
                    <td style={{ height: 'auto' }} />
                </tr>}
                {ios && <tr>
                    <td><Typography fontSize='sm'><Link endDecorator={<FA icon={faCircleQuestion} />} onClick={()=>setOpenTeamIdTips(true)}>Team ID</Link></Typography></td>
                    <td><TeamIdEdit key='ios_team' /></td>
                    <td />
                </tr>}
            </tbody>
        </Table>
        <TeamIdTips open={openTeamIdTips} onClose={()=>setOpenTeamIdTips(false)} />
    </>;
}


export const AppInfoPlistMockup: React.FC = () => {
    const app = React.useContext(AppWorkspaceContext);
    if (!app) return <AppConfigInvalid />;
    return <Table size='sm' sx={{ 'td' : { padding: 0 }, border: '1px solid #ccc'}}>
        <tbody>
            <tr>
                <td><Typography style={{ marginLeft: 36, color: '#bbb' }}>Other Properties...</Typography></td>
                <td width={30} />
                <td width={100} />
                <td width={160} />
                <td width={30} />
            </tr>
            <tr>
                <td><Typography startDecorator={<FA icon={faAngleDown} style={{ width: 32 }} />}>URL types</Typography></td>
                <td><UnfoldMore style={{ height: '0.75em', margin: 'auto' }} /></td>
                <td style={{ color: '#bbb' }}>Array</td>
                <td />
                <td />
            </tr>
            <tr>
                <td><Typography startDecorator={<FA icon={faAngleDown} style={{ marginLeft: 28 }} />}>Item (n)</Typography></td>
                <td />
                <td style={{ color: '#bbb' }}>Dictionary</td>
                <td />
                <td />
            </tr>
            <tr>
                <td><Typography startDecorator={<FA icon={faAngleDown} style={{ marginLeft: 40 }} />}>URL Schemes</Typography></td>
                <td><UnfoldMore style={{ height: '0.75em', margin: 'auto' }} /></td>
                <td style={{ color: '#bbb' }}>Array</td>
                <td />
                <td />
            </tr>
            <tr>
                <td><Typography style={{ marginLeft: 60 }}>Item (n)</Typography></td>
                <td />
                <td style={{ color: '#bbb' }}>String</td>
                <td><Typography color='primary' variant='soft' sx={{ width: 'max-content' }}>{app.deepLinking.scheme}</Typography></td>
                <td><Link sx={{ color: 'black', fontSize: 14 }} onClick={()=>navigator.clipboard.writeText(app.deepLinking.scheme)}><FA icon={faCopy} /></Link></td>
            </tr>
        </tbody>
    </Table>
}


export const AppCapabilityMockup: React.FC = () => {
    const app = React.useContext(AppWorkspaceContext);
    if (!app) return <AppConfigInvalid />;
    return <Box sx={{ borderRadius: '8px', border: '1px solid #ccc', '.svg-inline--fa': { color: '#ccc' }}}>
        <Box display='flex' alignItems='center' fontSize='sm' px={2} pt={2} gap={1}>
            <FA icon={faAngleDown} />
            <strong style={{ color: '#ccc' }}>Signing and other capabilities...</strong>
        </Box>
        <Divider sx={{ m: 2 }} />
        <Box display='flex' alignItems='center' fontSize='sm' px={2} gap={1}>
            <FA icon={faAngleDown} />
            <FA icon={faGlobe} />
            <strong>Associated Domains</strong>
            <span style={{ flex: 1 }} />
            <FA icon={faTrashCan} />
        </Box>
        <Box display='flex' fontSize='sm' alignItems='start' justifyContent='center' mx={13} my={4} gap={2}>
            Domains
            <Box>
                <Divider />
                <Typography fontSize='sm' color='primary' variant='soft'>{app.deepLinking.universalLink}</Typography>
                <Divider />
                <br />
                <Divider />
                <Box display='flex' gap={1} mt={1}><FA icon={faPlus} /><FA icon={faMinus} /></Box>
            </Box>
            <Link mt={0.5} onClick={()=>navigator.clipboard.writeText(app.deepLinking.universalLink)}><FA icon={faCopy} style={{ color: 'black' }} /></Link>
        </Box>
    </Box>;
}


export const AppTestConfig: React.FC = () => {
    const app = React.useContext(AppWorkspaceContext);
    if (!app) return <AppConfigInvalid />;
    return <LiveDemoForm appKey={app.key} appName={app.name} appIcon={app.icon} />;
}

const AppConfigInvalid: React.FC = () => {
    const nav = useNavigate();
    const { t } = useTranslation('dashboard');
    return <Link endDecorator={<KeyboardArrowRight />} fontSize='sm' onClick={()=>nav('/dashboard')}>{t('noAppToConfig')}</Link>;
}



