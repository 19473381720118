import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useLangNavigate as useNavigate } from '../modules/i18n';

import User from '../models/User';

import { Colors } from '../Resources';
import { Utils } from '../GlobalConfig';

import SEO from '../modules/SEO';
import { HeroCard } from './HeroCard';
import { EmailInput, PasswordInput } from './MoguaInput';
import { Typography, Button, Link, Chip, Divider, CircularProgress } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import { faCircleRight } from '@fortawesome/free-solid-svg-icons'


const Reset: React.FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();
    const verifyKey = searchParams.get('_verify_key');
	
	return (<>
		<SEO
            title="Mogua | Reset your password"
            description="Reset your Mogua account's password."
            url="https://www.mogua.io/reset" />
        {verifyKey ? <ResetRespond verifyKey={verifyKey} /> : <ResetRequest />}
    </>);
}

export default Reset;


const ResetRespond: React.FC<{ verifyKey: string }> = ({ verifyKey }) => {
	const { t } = useTranslation('account');
	const [resetKey, setResetKey] = React.useState<string | null>(null);
	const [error, setError] = React.useState<string | null>(null);
	const [loading, setLoading] = React.useState<boolean>(false);
	const nav = useNavigate();

	React.useEffect(() => {
		User.verifyEmail(verifyKey, false, {
			onSuccess: () => setResetKey(verifyKey),
			onError: (error) => setError(error),
		});
	}, [verifyKey]);

	const isMobile = Utils.isMobile();
	const size = isMobile ? "lg" : "md";
	
	if (resetKey == null) {
		return error ?
		<HeroCard title={t('verifyTitleFailed')}><Typography>{error}</Typography></HeroCard> :
		<HeroCard title={t('verifyTitleLoading')}><CircularProgress /></HeroCard>;
	} else {
		return <HeroCard title={t('resetTitle3')}>
			<form onSubmit={(event) => {
				event.preventDefault();
				const data = new FormData(event.currentTarget);

				// (?=.{6,}) - at least 6 characters or more
				// (?=.*\d) - at least 1 decimal
				// (?=.*[a-z]) - at least 1 lower case
				// (?=.*[A-Z]) - at least 1 upper case
				// (?!.*\W) - no special character
				const passwordReg = /^(?=.{6,}).*$/;
				const password = data.get('pwd') as string;
				if (passwordReg.test(password) === false) {
					setError(t('passwordRule'));
					return;
				}

				setLoading(true);
				User.resetPassword(password, resetKey, {
					onSuccess: () => nav('/sign-in'),
					onError: (error) => {
						setLoading(false);
						alert(error);
					},
				});
		    }}>
        		<PasswordInput required name='pwd' size={size} disabled={loading} errMsg={error} onChange={()=>setError(null)} />
				<Button size={size} type="submit" loading={loading} sx={{ width: '100%', mt: 2 }}>{t('resetButton')}</Button>
			</form>
        </HeroCard>;
	}
}


const ResetRequest: React.FC = () => {
	const { t } = useTranslation('account');
	const [emailValid, setEmailValid] = React.useState<string | null>(null);
	const [emailError, setEmailError] = React.useState<string | null>(null);
	const [resent, setResent] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(false);
	const nav = useNavigate();

	const isMobile = Utils.isMobile();
	const size = isMobile ? 'lg' : 'md';
	if (emailValid == null) {
		return <HeroCard title={t('resetTitle1')} suffix={
        	<Typography
				endDecorator={<Chip component='span' color='primary' onClick={()=>nav('/sign-in')} endDecorator={<FA icon={faCircleRight} />}>{t('restToSignIn2')}</Chip>}
				sx={{ color: Colors.gray3, fontSize: 14 }}>{t('restToSignIn1')}</Typography>}>
			<form onSubmit={(event) => {
				event.preventDefault();
				const data = new FormData(event.currentTarget);

				const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
				const email = data.get('email') as string;
				if (emailReg.test(email) === false) {
					setEmailError(t('emailInvalid'));
				} else {
					setLoading(true);
					User.checkEmail(email, {
						onSuccess: () => {
							setLoading(false);
							setEmailValid(email);
						},
						onError: (error) => {
							setLoading(false);
							setEmailError(error);
						},
					});
				}
		    }}>
        		<EmailInput required name='email' size={size} disabled={loading} errMsg={emailError} onChange={()=>setEmailError(null)} />
				<Button size={size} type='submit' loading={loading} sx={{ width: '100%', mt: 2 }}>{t('nextButton')}</Button>
			</form>
        </HeroCard>;
    } else if (!resent) {
		return <HeroCard title={t('resetTitle2')}>
			<Typography><Trans t={t} i18nKey='resetTips1' values={{ email: desensitizeEmail(emailValid!) }} /></Typography>
			<br />
			<Divider inset='none' />
			<Typography>
				<Trans t={t} i18nKey='resetTips2' components={[
					<Link component="button" onClick={()=>{
						User.resendEmail(emailValid!, {
							onSuccess: () => {},
							onError: (error) => alert(error),
						});
						setResent(true);
					}} />,
					<Link onClick={()=>{
						setEmailValid(null);
						setEmailError(null);
						setResent(false);
						setLoading(false);
					}} />,
				]} />
			</Typography>
		</HeroCard>;
	} else {
    	return <HeroCard title={t('resetTitle2')}>
			<Typography><Trans t={t} i18nKey='resetTips3' values={{ email: desensitizeEmail(emailValid!) }} /></Typography>
			<br />
			<Divider inset='none' />
			<Typography>
				<Trans t={t} i18nKey='resetTips4' components={[
					<Link onClick={()=>{
						setEmailValid(null);
						setEmailError(null);
						setResent(false);
						setLoading(false);
					}} />,
				]} />
			</Typography>
		</HeroCard>;
	}
}

const desensitizeEmail = (email: string) => {
	const i = email.indexOf("@")
	return email.substring(0, i > 4 ? i - 4 : 1) + "****@****" + email.substring(i + 5 < email.length ? i + 5 : email.length - 1);
}


