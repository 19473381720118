import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Platform, EventType, DataFilter } from '../models/Data';
import { useSettings } from '../models/Settings';
import { Colors } from '../Resources';

import { Card, Input, Select, Option, Divider, Button, Typography, Box } from '@mui/joy';
import { Dropdown, MenuButton, Menu, MenuItem } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faMinus, faArrowRightLong, faMagnifyingGlass, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';

import dayjs, { Dayjs } from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
dayjs.extend(minMax);

export const platformReadable = new Map<Platform, string>([
	[Platform.all, 'platform.all'],
	[Platform.android, 'platform.android'],
	[Platform.ios, 'platform.ios'],
]);

export const eventTypeReadable = new Map<EventType, string>([
	[EventType.all, 'eventType.all'],
	[EventType.appDownload, 'eventType.appDownload'],
	[EventType.appInstalled, 'eventType.appInstalled'],
	[EventType.appWakeup, 'eventType.appWakeup'],
]);

const dataFilterToSearchParams = (filter: DataFilter) => {
	const sp: URLSearchParams = new URLSearchParams();
	sp.set('pk', filter.paramKey);
	sp.set('pv', filter.paramValue);
	sp.set('pf', filter.platform);
	sp.set('et', filter.eventType);
	sp.set('start', filter.dateStart.format('YYYY-MM-DD'));
	sp.set('end', filter.dateEnd.format('YYYY-MM-DD'));
	return sp;
}

const searchParamsToDataFilter = (sp: URLSearchParams) => {
	let d1 = dayjs.tz(sp.get('start'));
	d1 = d1.isValid() ? dayjs.min(d1, dayjs.tz()) : dayjs.tz();
	let d2 = dayjs.tz(sp.get('end'));
	d2 = d2.isValid() ? dayjs.min(d2, dayjs.tz()) : dayjs.tz();
	return {
		paramKey: sp.get('pk'),
		paramValue: sp.get('pv'),
		platform: sp.get('pf') ?? Platform.all,
		eventType: sp.get('et') ?? EventType.all,
		dateStart: dayjs.min(d1, d2)?.startOf('d'),
		dateEnd: dayjs.max(d1, d2)?.endOf('d'),
	} as DataFilter;
}

interface FilterToolbarProps {
	loading?: boolean;
	allowEventTypeFilter?: boolean;
	onFiltered?: (filter: DataFilter) => void;
}

const dateInputSx = {
	min: '1970-01-01',
	max: '2042-12-31',
	width: 130,
}

const FilterToolbar: React.FC<FilterToolbarProps> = ({ loading = false, allowEventTypeFilter = true, onFiltered }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const filter = React.useMemo(()=>searchParamsToDataFilter(searchParams), [searchParams]);
	const [start, setStart] = React.useState<Dayjs>(filter.dateStart);
	const [end, setEnd] = React.useState<Dayjs>(filter.dateEnd);
	React.useEffect(()=>onFiltered && onFiltered(filter), [filter, onFiltered]);
	const { t } = useTranslation('dataFilter');

	return <Card component='form' orientation='horizontal' sx={{
		alignItems: 'center',
		flexWrap: 'wrap',
		'& .fa-arrow-right': { color: Colors.gray2 },
	}} onSubmit={(event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const fi = searchParamsToDataFilter(data as URLSearchParams);
		const sp = dataFilterToSearchParams(fi);
		setSearchParams(sp);
	}}>
		<Input size='sm' disabled={loading} type='search' name='pk' placeholder={t('paramName')} defaultValue={filter.paramKey} />
		<Input size='sm' disabled={loading} type='search' name='pv' placeholder={t('paramValue')} defaultValue={filter.paramValue} />
		<Divider inset='none' />
		<Select size='sm' disabled={loading} name='pf' defaultValue={filter.platform} sx={{ minWidth: 150 }}>
			{Array.from(platformReadable.entries()).map(e => <Option key={e[0]} value={e[0]}>{t(e[1])}</Option>)}
		</Select>
		<Select size='sm' disabled={!allowEventTypeFilter || loading} name='et' defaultValue={filter.eventType} sx={{ minWidth: 150 }}>
			{Array.from(eventTypeReadable.entries()).map(e => <Option key={e[0]} value={e[0]}>{t(e[1])}</Option>)}
		</Select>
		<Divider inset='none' />
		<Input size='sm' disabled={loading} type='date' key={'s' + start?.format()} defaultValue={start?.format('YYYY-MM-DD')} name='start'
			slotProps={{ input: { max: dayjs.tz().format('YYYY-MM-DD') } }} sx={dateInputSx} />
		<Box display='flex' flexDirection='column' alignItems='center'>
			<span style={{ width: 'max-content', height: '20px', color: Colors.gray2 }}>
				<FA icon={faMinus} /><FA icon={faMinus} /><FA icon={faMinus} /><FA icon={faArrowRightLong} />
			</span>
			<Typography fontSize={10} textColor={Colors.gray3}>{dayjs.tz().format('([GMT]Z)')}</Typography>
		</Box>
		<Input size='sm' disabled={loading} type='date' key={'e' + end?.format()} defaultValue={end?.format('YYYY-MM-DD')} name='end'
			slotProps={{ input: { max: dayjs.tz().format('YYYY-MM-DD') } }} sx={dateInputSx} />
		<DateMenu loading={loading} onSelected={(start, end)=>{
			setStart(start);
			setEnd(end);
		}} />
		<Button size='sm' loading={loading} type='submit' startDecorator={<FA icon={faMagnifyingGlass} />}>{t('search')}</Button>
	</Card>;
}

export default FilterToolbar;


export const DateMenu: React.FC<{ loading?: boolean, onSelected: (start: Dayjs, end: Dayjs) => void }> = ({ loading = false, onSelected }) => {
	const { t } = useTranslation('dataFilter');
	const settings = useSettings();

	return <Dropdown>
		<MenuButton size='sm' disabled={loading}><FA icon={faCalendarCheck} /></MenuButton>
		<Menu size='sm'>
			<MenuItem onClick={()=>{
				let now = dayjs.tz();
				let start = now.startOf('d');
				let end = now.endOf('d');
				onSelected(start, end);
			}}>{t('today')}
				<Typography fontSize='sm' textColor={Colors.gray2}>{dayjs.tz().format(settings.dateFormat + ' ([GMT]Z)')}</Typography>
			</MenuItem>
			<MenuItem onClick={()=>{
				let yesterday = dayjs.tz().subtract(1, 'd');
				let start = yesterday.startOf('d');
				let end = yesterday.endOf('d');
				onSelected(start, end);
			}}>{t('yesterday')}</MenuItem>
			<Divider />
			<MenuItem onClick={()=>{
				let now = dayjs.tz();
				let start = now.startOf('w');
				let end = now.endOf('d');
				onSelected(start, end);
			}}>{t('thisWeek')}</MenuItem>
			<MenuItem onClick={()=>{
				let now = dayjs.tz();
				let start = now.startOf('M');
				let end = now.endOf('d');
				onSelected(start, end);
			}}>{t('thisMonth')}</MenuItem>
			<Divider />
			<MenuItem onClick={()=>{
				let now = dayjs.tz();
				let end = now.startOf('w').tz().subtract(1, 'd').endOf('d');
				let start = end.startOf('w');
				onSelected(start, end);
			}}>{t('lastWeek')}</MenuItem>
			<MenuItem onClick={()=>{
				let now = dayjs.tz();
				let end = now.startOf('M').tz().subtract(1, 'd').endOf('d');
				let start = end.startOf('M');
				onSelected(start, end);
			}}>{t('lastMonth')}</MenuItem>
			<MenuItem onClick={()=>{
				let yesterday = dayjs.tz().subtract(1, 'd');
				let start = yesterday.subtract(6, 'd').startOf('d');
				let end = yesterday.endOf('d');
				onSelected(start, end);
			}}>{t('last7Days')}</MenuItem>
			<MenuItem onClick={()=>{
				let yesterday = dayjs.tz().subtract(1, 'd');
				let start = yesterday.subtract(29, 'd').startOf('d');
				let end = yesterday.endOf('d');
				onSelected(start, end);
			}}>{t('last30Days')}</MenuItem>
		</Menu>
	</Dropdown>;
}


