import * as React from 'react';
import i18n from 'i18next';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import HttpApi from 'i18next-http-backend';

import { initReactI18next } from 'react-i18next';
import { useNavigate, useParams, Outlet } from 'react-router-dom';

import { serviceConfig } from "../GlobalConfig";

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: serviceConfig.lng,
        backend: {
            backends: [
                // LocalStorageBackend should be applied only when the corpus is rarely changed.
                // LocalStorageBackend,
                HttpApi,
            ],
            backendOptions: [
                // LocalStorageBackend should be applied only when the corpus is rarely changed.
                // {
                //     // options for LocalStorageBackend
                // },
                {
                    loadPath: '/locales/{{lng}}/{{ns}}.json',          
                },
            ],
        },
    });


export function useLangNavigate() {
    const nav = useNavigate();
    const lang = i18n.language === serviceConfig.lng ? undefined : i18n.language;
    return React.useCallback((path: string, args?: any)=>{
        if (path.at(0) !== '/' || !lang) {
            nav(path, args);
            return;
        }
        const multiSeg = path.split('/').length > 2;
        const hasLangSeg = i18n.languages.find((e)=>path.startsWith('/' + e + (multiSeg ? '/' : '')));
        nav(hasLangSeg ? path : ('/' + lang + path), args);
    }, [nav, lang]);
}


export const I18nWrapper: React.FC = () => {
    const { lang } = useParams();
    React.useEffect(()=>{        
        i18n.changeLanguage(lang);
    }, [lang]);
    return <Outlet />;
}