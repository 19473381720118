import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLangNavigate as useNavigate } from '../modules/i18n';

import SEO from '../modules/SEO';
import NavBar from './NavBar';
import Footer from './Footer';
import Markdown from './MoguaMarkdown';
import { Workspace } from './Workspace';
import { WorkspaceSection } from './WorkspaceSection';
import { Divider } from '@mui/joy';
import { List, ListItemDecorator, ListItemButton } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faShieldHalved, faGavel } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';

library.add([faShieldHalved, faGavel]);

const Terms: React.FC = () => {
	const nav = useNavigate();
	const location = useLocation();
	const { t } = useTranslation('docs');
	const docs = t('agreement', { returnObjects: true, defaultValue: [] }) as Array<any>;
	const doc = docs.find(e => location.pathname.toLowerCase().includes(e.id)) ?? docs[0];
	
	const replaces = new Map([
		['${brand}', 'Omnimind'],
    	['${website}', 'www.mogua.io'],
	]);

	return (<>
		<SEO
			title="Mogua: Web to app attribution solution"
			description="Web to app parameter passing solution. Track Android and iOS apps' installation from web referrals with our attribution SDK."
			url={`https://www.mogua.io${location.pathname}`} />
		<NavBar wide />
		<Divider />
		<Workspace sidebar={
			<List size="sm">
				{docs.map(e => <ListItemButton key={e.id} selected={doc?.id === e.id} onClick={()=>nav('/' + e.id)}>
					<ListItemDecorator><FA icon={e.icon} /></ListItemDecorator>{e.title}
				</ListItemButton>)}
			</List>
		}>
			{doc && <WorkspaceSection>
				<Markdown src={doc.src} replaces={replaces} />
			</WorkspaceSection>}
		</Workspace>
		<Footer />
	</>);
}

export default Terms;
