import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { AppWorkspaceContext } from './AppWorkspace';
import { useSettings } from '../models/Settings';
import { Colors } from '../Resources';
import Data from '../models/Data';
import dayjs from 'dayjs';

import FilterToolbar, { eventTypeReadable } from './FilterToolbar';
import WorkspaceData from './WorkspaceData';
import { NotifyExpired, NotifyTestMode } from './Notifications';
import { GridColDef } from '@mui/x-data-grid';
import { Typography } from '@mui/joy';


const AppParameters: React.FC = () => {
	const app = React.useContext(AppWorkspaceContext)!;
	const { t } = useTranslation('dataFilter');
	const settings = useSettings();

	const config = React.useMemo(()=>{
		return Array.from<GridColDef>([
			{ field: 'TagName', headerName: t('paramName') },
			{ field: 'TagValue', headerName: t('paramValue') },
			{ field: 'Count', headerName: t('count'), valueGetter: (value: number) => value.toLocaleString() },
			{ field: 'EventName', headerName: t('event'), valueGetter: (value) => t(eventTypeReadable.get(value) ?? 'undefined') },
			{
				field: 'FirstAppear',
				headerName: t('firstReport'),
				renderHeader: ()=><Typography fontSize='sm' fontWeight='bold'>
					{t('firstReport')}
					<Typography ml={1} fontSize={12} fontWeight='normal' textColor={Colors.gray3}>{dayjs.tz().format('([GMT]Z)')}</Typography>
				</Typography>,
				valueGetter: (value) => dayjs(value * 1000).tz().format(settings.dateFormat + ' HH:mm:ss') },
			{
				field: 'LastAppear',
				headerName: t('lastReport'),
				renderHeader: ()=><Typography fontSize='sm' fontWeight='bold'>
					{t('lastReport')}
					<Typography ml={1} fontSize={12} fontWeight='normal' textColor={Colors.gray3}>{dayjs.tz().format('([GMT]Z)')}</Typography>
				</Typography>,
				valueGetter: (value) => dayjs(value * 1000).tz().format(settings.dateFormat + ' HH:mm:ss') },
		]).map((e) => {
			e.disableColumnMenu = true;
			e.resizable = false;
			e.sortable = false;
			e.flex = 1;
			e.align = 'center';
			e.headerAlign = 'center';
			return e;
		});
	}, [t, settings.dateFormat]);

	const [data, setData] = React.useState<Array<Object>>();
	const [loading, setLoading] = React.useState<boolean>(false);

	const onFiltered = React.useCallback((filter: any)=>{
		setData(undefined);
		setLoading(true);
		filter.appId = app.id;
		const data = new Data(filter);
		data.getParameters({
			onSuccess: (data) => {
				setLoading(false);
				setData(data);
			},
			onError: (error) => {
				setLoading(false);
				alert(error);
			},
		});
	}, [app.id]);

	return (<>
		{app.isExpired() && <NotifyExpired />}
		{app.isTestEnabled && <NotifyTestMode />}
		<FilterToolbar loading={loading} onFiltered={onFiltered} />
		<WorkspaceData data={data} config={config} loading={loading} />
	</>);
}

export default AppParameters;


