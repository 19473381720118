import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLangNavigate as useNavigate } from '../modules/i18n';

import dayjs from 'dayjs';
import { Colors, Dimens, MoguaSVG } from '../Resources';

import { useSettings } from '../models/Settings';

import { Box, Stack, Typography, Link, Divider, Chip, Badge } from '@mui/joy';


const Footer: React.FC = () => {
    const settings = useSettings();
    const { t } = useTranslation(['common', 'docs']);
    const docs = t('agreement', { ns: 'docs', returnObjects: true, defaultValue: [] }) as Array<any>;
    const nav = useNavigate();

    const textSx = { color: Colors.gray3, fontSize: 12, 'textDecorationColor': Colors.gray2 };

    return <Box component='nav' display='flex' alignItems='center' justifyContent='center' sx={{ bgcolor: Colors.grayBG }}>
        <Stack flex={1} m={2} spacing={2} sx={{ maxWidth: Dimens.maxWidth }}>
            <Box display='flex' flexDirection='row'>
                <MoguaSVG color={Colors.gray3} size='sm' />
                <Badge size='sm' color='danger' badgeInset='4%' invisible={settings.isVersionKnown()} sx={{ mx: 1 }}>
                    <Chip variant='outlined' onClick={()=>nav('/changelog')}>
                        <Typography fontSize='xs' textColor='#00000033' >{settings.version.replace(/(mogua-site-v|-release)/g, '')}</Typography>
                    </Chip>
                </Badge>
            </Box>
            <Divider />
            <Box display='flex' flexWrap='wrap' gap={3} sx={{ width: '100%' }}>
                <Typography sx={textSx}>{t('copyright', { year: dayjs().year() })}</Typography>
                {docs.flatMap(e => [
                    <Divider key={e.id + '-divider'} orientation='vertical' sx={{ height: '0.75rem', alignSelf: 'center' }} />,
                    <Link key={e.id} sx={textSx} onClick={()=>nav('/' + e.id)}>{e.title}</Link>,
                ])}
                <Box display='flex' justifyContent='flex-end' sx={{ flex: 1 }}><img alt='mogua logo' src='/images/omnimind_grey_icon.svg' width='100px' /></Box>
            </Box>
        </Stack>
    </Box>;
}

export default Footer;